import { Api, DataResponse, SentryReporter } from "imagine-essentials";
import {
  SubscriptionProduct,
  SubscriptionCoupon,
  Subscription,
  SubscriptionPaymentMethod,
  SubscriptionTaxRate,
  MembershipsTools,
  CompanyDetails,
  SubscriptionInvoice,
  Membership,
  MembershipType,
} from "..";
import { Data } from "react-facebook-pixel";

const getAllProducts = async () => {
  const result = await Api.get("api/subscriptions/products-all");
  const response: DataResponse<SubscriptionProduct[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data.map((product: any) => {
        return {
          ...product,
          featureKeys: product.featureKeys
            ? JSON.parse(product.featureKeys)
            : [],
        } as SubscriptionProduct;
      });
    } catch (error) {
      SentryReporter.captureException("Failed to parse products", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get products", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getActiveProducts = async () => {
  const result = await Api.get("api/subscriptions/products");
  const response: DataResponse<SubscriptionProduct[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data.map((product: any) => {
        return {
          ...product,
          featureKeys: product.featureKeys
            ? JSON.parse(product.featureKeys)
            : [],
        } as SubscriptionProduct;
      });
    } catch (error) {
      SentryReporter.captureException("Failed to parse products", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get products", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const saveProduct = async (product: SubscriptionProduct) => {
  const data = {
    ...product,
    featureKeys: JSON.stringify(product.featureKeys),
  };
  const result = await Api.post(
    "api/subscriptions/update-product/" + product.id,
    data
  );
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to save product", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getAllCoupons = async () => {
  const result = await Api.get("api/subscriptions/coupons-all");
  const response: DataResponse<SubscriptionCoupon[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data;
    } catch (error) {
      SentryReporter.captureException("Failed to parse coupons", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get coupons", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getAvailableCoupons = async () => {
  const token = MembershipsTools.getDealToken();
  const result = await Api.get("api/subscriptions/coupons/" + token);
  const response: DataResponse<SubscriptionCoupon[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data;
    } catch (error) {
      SentryReporter.captureException("Failed to parse coupons", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get coupons", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const saveCoupon = async (coupon: SubscriptionCoupon) => {
  const result = await Api.post(
    "api/subscriptions/update-coupon/" + coupon.id,
    coupon
  );
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to save coupon", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getAllTaxRates = async () => {
  const result = await Api.get("api/subscriptions/tax-rates-all");
  const response: DataResponse<SubscriptionTaxRate[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data;
    } catch (error) {
      SentryReporter.captureException("Failed to parse tax rates", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get tax rates", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getUserSubscriptionData = async (userId?: number) => {
  const url = userId
    ? "api/subscriptions/user/" + userId
    : "api/subscriptions/current-user";
  const result = await Api.get(url);
  const response: DataResponse<{
    subscriptions: Subscription[];
    paymentMethod: SubscriptionPaymentMethod | null;
    // otherInvoices: SubscriptionInvoice[];
  }> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data;
    } catch (error) {
      SentryReporter.captureException(
        "Failed to parse user subscription data",
        {
          "Server response": JSON.stringify(result),
        }
      );
    }
  } else {
    SentryReporter.captureException("Failed to get user subscription data", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const addCompanyDetails = async (companyDetails: CompanyDetails) => {
  const result = await Api.post(
    "api/subscriptions/company-details",
    companyDetails
  );
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to add company details", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const updateCompanyDetails = async (companyDetails: CompanyDetails) => {
  const result = await Api.post(
    "api/subscriptions/update-company-details/" + companyDetails.id,
    companyDetails
  );
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to update company details", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getAllCompanyDetails = async () => {
  const result = await Api.get("api/subscriptions/company-details-all");
  const response: DataResponse<CompanyDetails[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data;
    } catch (error) {
      SentryReporter.captureException("Failed to parse company details", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get company details", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const syncProducts = async () => {
  const result = await Api.post("api/stripe/synchronize", {});
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to sync products", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const migrateUser = async (userId: number) => {
  const result = await Api.post("api/subscriptions/migrate-user/" + userId, {});
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to migrate user", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getSubscriptionStatus = async (
  startDate: string,
  endDate: string,
  country?: string,
  type?: string,
  coupons?: number[]
) => {
  const result = await Api.post("api/subscriptions/status", {
    startDate,
    endDate,
    country,
    type,
    coupons,
  });
  const response: DataResponse<{
    invoices: SubscriptionInvoice[];
    memberships: Membership[];
    initial: number;
  }> = {
    success: result.success,
  };
  if (result.success && result.data) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = result.data;
    } catch (error) {
      response.errorMessage = "Failed to parse subscription status";
      SentryReporter.captureException("Failed to parse subscription status", {
        "Server response": JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get subscription status", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

export const SubscriptionDataApi = {
  getAllProducts,
  getActiveProducts,
  saveProduct,
  getAllCoupons,
  getAvailableCoupons,
  saveCoupon,
  getAllTaxRates,
  getUserSubscriptionData,
  addCompanyDetails,
  updateCompanyDetails,
  getAllCompanyDetails,
  syncProducts,
  migrateUser,
  getSubscriptionStatus,
};
