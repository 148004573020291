import { Delimiter } from "../types";

const round = (value: number, decimals: number) => {
  let factor = 1;
  for (let i = 0; i < decimals; i++) {
    factor = factor * 10;
  }
  return Math.round(value * factor) / factor;
};

const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

const radiansToDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

/**
 * Get a random number between 0 and max.
 * @returns
 */
const getRandomNumber = (max = 100) => {
  const n = Math.random();
  return Math.round(n * max);
};

const getMin = (list: number[]) => {
  let min = list[0];
  for (let i = 1; i < list.length; i++) {
    if (list[i] < min) min = list[i];
  }
  return min;
};

/**
 * Use this to make sure only the allowed number of decimals are displayed (avoid
 * floating point errors).
 * @param value The number to convert
 * @param decimals Number of decimals. Default is 2.
 * @param delimiter Delimiter to separate decimals numbers. Default is ".".
 * @returns
 */
const numberToText = (
  value: number,
  decimals: number | undefined,
  delimiter?: Delimiter
) => {
  const d = decimals || 2;
  const s = delimiter || ".";
  let text = round(value, d).toString();
  const pos = text.search(".");
  if (pos >= 0) {
    if (pos + d >= text.length) {
      text = text.substring(0, pos + d + 1);
    }
  }
  return text.replace(".", s);
};

/**
 * Converts a string to a number. It will always return the best attempt, event if the string
 * is not a proper number format. No support for thousand separator. Works with botg "." and ","
 * as decimal separator.
 */
const textToNumber = (text: string) => {
  let sanitizedText = text.replace(/[,]+/g, ".");
  const n = sanitizedText.split(".", text.length);
  if (n.length > 2) {
    sanitizedText = n[0] + "." + n[1];
  }
  sanitizedText = sanitizedText.replace(/[^0-9.]+/g, "");
  return Number(sanitizedText);
};

/**
 * Removes the http, https or www prefix.
 */
const sanitizeUrl = (url: string) => {
  url = url.replace("https://", "");
  url = url.replace("http://", "");
  url = url.replace("www.", "");
  const list = url.split("/");
  if (list.length > 0) return list[0];
  else return "";
};

const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const Tools = {
  round,
  degreesToRadians,
  radiansToDegrees,
  numberToText,
  textToNumber,
  sanitizeUrl,
  getRandomNumber,
  getMin,
  sleep,
};
