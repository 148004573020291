import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./i18n";
import "./style/main.scss";
import {
  ActivateAccountContainer,
  DealSaverContainer,
  InvoiceContainer,
  LayoutContainer,
  RestorePasswordContainer,
} from "./containers";
import { PageUrl } from "imagine-pages";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestStatus, Tools } from "imagine-essentials";
import { useAppDispatch, useAppSelector } from "./store/store";
import { UserSelector, UserThunks } from "imagine-users";

const App = () => {
  const { i18n } = useTranslation();

  const user = useAppSelector(UserSelector.getUserNotNull);
  const userStatus = useAppSelector(UserSelector.getStatus);
  const dispatch = useAppDispatch();

  const loadData = useCallback(async () => {
    if (userStatus === RequestStatus.IDLE) {
      dispatch(UserThunks.fetchLoggedInUser());
    }
  }, [userStatus, dispatch, user.language]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const loadWebsiteLanguage = useCallback(async () => {
    const location = window.location;
    const language = PageUrl.getLanguageFromUrl(location.pathname);
    let languageCode = "en-GB";
    switch (language) {
      case "da":
        languageCode = "da";
        break;

      default:
    }
    if (i18n.language !== languageCode) {
      await i18n.changeLanguage(languageCode);
    }
  }, [i18n]);

  useEffect(() => {
    loadWebsiteLanguage();
  }, [loadWebsiteLanguage]);

  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route
          path="/users/reset-password/:resetCode"
          element={<RestorePasswordContainer />}
        ></Route>
        <Route
          path="/:language/users/reset-password/:resetCode"
          element={<RestorePasswordContainer />}
        ></Route>
        <Route
          path="/users/activate/:activationCode"
          element={<ActivateAccountContainer />}
        ></Route>
        <Route
          path="/:language/users/activate/:activationCode"
          element={<ActivateAccountContainer />}
        ></Route>

        <Route
          path="/special-deal/:token"
          element={<DealSaverContainer />}
        ></Route>
        <Route
          path="/pdf/invoice/:invoiceId"
          element={<InvoiceContainer />}
        ></Route>
        <Route path="*" element={<LayoutContainer />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
