import { useEffect } from "react";
import { AccordionContent, AccordionContentItem } from "../..";
import { Accordion, AccordionItem } from "imagine-ui";

type Props = {
  content: AccordionContent;
};

/**
 * Display a preview of the accordion.
 */
export const AccordionContentView = (props: Props) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="accordion-content">
        <Accordion>
          {props.content.items.map(
            (item: AccordionContentItem, index: number) => (
              <AccordionItem
                id={index.toString()}
                key={index.toString()}
                title={item.title}
              >
                <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
              </AccordionItem>
            )
          )}
        </Accordion>
      </div>
    </>
  );
};
