export enum DateTimeFormat {
  BACKEND_DATE,
  BACKEND_TIME,
  BACKEND_DATE_AND_TIME,
  LONG_DATE,
  SHORT_DATE,
  SHORT_DATE_NO_YEAR,
  DATE_AND_TIME,
  SHORT_DATE_AND_TIME,
  SHORT_TIME,
  LONG_TIME,
  LONG_DATE_AND_TIME,
}
