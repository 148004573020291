import { ProductCard } from "imagine-ui";
import { DisplayProduct, MembershipsText } from "..";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CurrencyTools } from "imagine-i18n";

interface Props {
  product: DisplayProduct;
  elementId?: string;
  hideButton?: boolean;
}

export const SubscriptionProductCard = (props: Props) => {
  const { t } = useTranslation();

  const initialCoupon = useMemo(() => {
    return props.product.pricing[0].coupons.find(
      (coupon) => coupon.duration === "once"
    );
  }, [props.product.pricing]);

  const recurringCoupon = useMemo(() => {
    return props.product.pricing[0].coupons.find(
      (coupon) => coupon.duration === "forever"
    );
  }, [props.product.pricing]);

  const discount = useMemo(() => {
    if (initialCoupon) {
      return initialCoupon.percentOff / 100;
    } else if (recurringCoupon) {
      return recurringCoupon.percentOff / 100;
    } else {
      return undefined;
    }
  }, [initialCoupon, recurringCoupon]);

  const period = useMemo(() => {
    const coupon = initialCoupon || recurringCoupon;
    if (coupon) {
      const count = coupon.duration === "once" ? 1 : coupon.durationInMonths;
      if (count && props.product.pricing[0].billingInterval === "month") {
        return t("memberships:firstXMonths", {
          count: count,
        });
      } else if (count && props.product.pricing[0].billingInterval === "year") {
        return t("memberships:firstXYears", {
          count: count,
        });
      }
    }

    return MembershipsText.getProductPeriodText(
      props.product.pricing[0].billingInterval
    );
  }, [initialCoupon, props.product.pricing, recurringCoupon, t]);

  const couponName = useMemo(() => {
    if (initialCoupon) {
      return initialCoupon.name;
    } else if (recurringCoupon) {
      return recurringCoupon.name;
    }
    return undefined;
  }, [initialCoupon, recurringCoupon]);

  const subPeriod = useMemo(() => {
    if (recurringCoupon === undefined) return "";
    if (initialCoupon === undefined) return "";

    const originalPrice = props.product.pricing[0].amount;
    const recurringPrice =
      originalPrice - originalPrice * (recurringCoupon.percentOff / 100);
    const recurringPriceText = CurrencyTools.getDisplayPrice(
      recurringPrice,
      props.product.pricing[0].currency,
      false
    );

    const text =
      t("memberships:hereafterRenewsAt") +
      " " +
      recurringPriceText +
      " " +
      MembershipsText.getProductPeriodText(
        props.product.pricing[0].billingInterval
      );
    return text;
  }, [initialCoupon, props.product.pricing, recurringCoupon, t]);

  const couponDescription = useMemo(() => {
    if (initialCoupon) {
      return t(initialCoupon.descriptionKey);
    } else if (recurringCoupon) {
      return t(recurringCoupon.descriptionKey);
    }
    return undefined;
  }, [initialCoupon, recurringCoupon, t]);

  return (
    // <div>
    //   <p>{props.product.title}</p>
    // </div>
    <ProductCard
      title={props.product.title}
      features={props.product.features || []}
      buttonText={props.product.buttonText}
      price={props.product.pricing[0].amount}
      currency={props.product.pricing[0].currency}
      // footerText={changeMembershipText}
      discount={discount}
      discountName={couponName}
      discountDescription={couponDescription}
      period={period}
      subPeriod={subPeriod}
      onButtonClick={props.product.onButtonClick}
      // actualPrice={props.membership !== undefined}
      elementId={props.elementId}
      hideButton={props.hideButton}
    />
  );
};
