import ImagineConfig from "../../../../../ImagineConfig";
import { DeviceSize } from "../enums";

const isTouchDevice = () => {
  return "ontouchstart" in document.documentElement;
};

const getWindowWidth = () => {
  return window.innerWidth;
};

const getWindowHeight = () => {
  return window.innerHeight;
};

/**
 * Check if the device is this given size or smaller.
 */
const isMaxSize = (width: number, size: DeviceSize) => {
  switch (size) {
    case DeviceSize.XS:
      return width <= ImagineConfig.breakpoints.xs;
    case DeviceSize.SM:
      return width <= ImagineConfig.breakpoints.sm;
    case DeviceSize.MD:
      return width <= ImagineConfig.breakpoints.md;
    case DeviceSize.LG:
      return width <= ImagineConfig.breakpoints.lg;
    case DeviceSize.XL:
      return width <= ImagineConfig.breakpoints.xl;
    case DeviceSize.XXL:
      return width <= ImagineConfig.breakpoints.xxl;
    default:
      throw new Error("Device size not supported: " + size);
  }
};

/**
 * Checks if the device id this given size or larger. DEPRECATED!
 */
const isMinSize = (width: number, size: DeviceSize) => {
  switch (size) {
    case DeviceSize.XXL:
      return width > ImagineConfig.breakpoints.xxl;
    case DeviceSize.XL:
      return width > ImagineConfig.breakpoints.xl;
    case DeviceSize.LG:
      return width > ImagineConfig.breakpoints.lg;
    case DeviceSize.MD:
      return width > ImagineConfig.breakpoints.md;
    case DeviceSize.SM:
      return width > ImagineConfig.breakpoints.sm;
    case DeviceSize.XS:
      return width > ImagineConfig.breakpoints.xs;
    default:
      throw new Error("Device size not supported: " + size);
  }
};

export const Device = {
  isTouchDevice,
  getWindowWidth,
  getWindowHeight,
  isMaxSize,
  isMinSize,
};
