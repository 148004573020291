export enum UserRole {
  NONE = 0,
  STANDARD = 1,
  PREMIUM = 2,
  MODERATOR = 3,
  TRANSLATOR = 4,
  ADMIN = 5,
  PRO = 6,
  PARTNER = 7,
  _SIZE = 8,
}
