import { Page, PageContent } from "..";

/**
 * In case the content field is empty, it will be converted to an empty array.
 * @param dbPage
 */
const parsePageFromDb = (dbPage: any) => {
  let content: PageContent[] = [];
  if (dbPage.content !== undefined) {
    try {
      if (dbPage.content !== "") content = JSON.parse(dbPage.content);
    } catch (e) {
      console.error(e);
      throw new Error("Unable to parse page content. Not valid JSON.");
    }
  }
  return {
    ...dbPage,
    content: content,
  } as Page;
};

/**
 * Converts an array of pages to a key page structure.
 * @param pages
 * @returns
 */
const getKeyPages = (pages: Page[]) => {
  const keys: number[] = [];
  pages.forEach((page) => {
    if (page.keyId !== undefined && !keys.includes(page.keyId)) {
      keys.push(page.keyId);
    }
  });

  return keys.map((keyId: number) => {
    let titleKey = "";
    const keyPage = pages.find((page: Page) => page.keyId === keyId);
    if (keyPage?.titleKey) {
      titleKey = keyPage.titleKey;
    }
    const pagesForKey = pages.filter(
      (page) => page.keyId === keyId && page.id !== null
    );

    return {
      id: keyId,
      titleKey: titleKey,
      pages: pagesForKey,
    };
  });
};

export const PageConverter = {
  parsePageFromDb,
  getKeyPages,
};
