interface Props {
  children: React.ReactNode;
  brand: React.ReactNode;
  title: string;
}

export const MessageBox = (props: Props) => {
  return (
    <>
      <div className="message-box-bg">
        <div className="row items-center">
          <div className="message-box-brand">{props.brand}</div>
        </div>
        <div className="row items-center">
          <div className="modal-dialog">
            <div className="modal-body">
              <div className="text-center">
                <h5 className="mb-lg">{props.title}</h5>
              </div>

              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
