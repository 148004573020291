import {
  ContentView,
  ErrorPage,
  Page,
  PageContent,
  SnippetBaseProps,
  SnippetContentViewProps,
} from "..";

import { Helmet } from "react-helmet";

import { ErrorCode } from "../enums";
import { useTranslation } from "react-i18next";
import { Language } from "imagine-i18n";

interface Props {
  language: Language;
  page: Page | undefined;
  loading?: boolean;
  SnippetContentViewComponent: React.ComponentType<SnippetContentViewProps>;
  snippetProps: SnippetBaseProps;
  // snippetContentView?: React.ReactNode;
}

export const WebPage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="page-content">
      {props.page === undefined && !props.loading && (
        <>
          <Helmet>
            <html lang={props.language} />
            <title>404 - {t("pages:pageCannotBeFound")}</title>
            <meta
              name="description"
              content="Denne side findes desværre ikke"
            ></meta>
            <meta name="robots" content="noindex" data-react-helmet="true" />
          </Helmet>
          <ErrorPage error={ErrorCode.NOT_EXIST} language={props.language} />
        </>
      )}
      {/* {props.page !== undefined && props.loading && (
        <Helmet>
          <html lang={props.language} />
          <title>{props.page.title}</title>
          <meta name="description" content={props.page.metaDescription}></meta>
        </Helmet>
      )} */}
      {props.page !== undefined && (
        <>
          <Helmet>
            <html lang={props.language} />
            <title>{props.page.title}</title>
            <meta
              name="description"
              content={props.page.metaDescription}
            ></meta>
          </Helmet>
          {!props.page.published && (
            <ErrorPage
              error={ErrorCode.NOT_PUBLISHED}
              language={props.language}
            />
          )}
          {props.page.published &&
            props.page.content !== undefined &&
            props.page.content.map((content: PageContent, index: number) => (
              <ContentView
                content={content}
                key={index.toString()}
                SnippetContentViewComponent={props.SnippetContentViewComponent}
                snippetProps={props.snippetProps}
                // snippetContentView={props.snippetContentView}
              />
            ))}
        </>
      )}
    </div>
  );
};
