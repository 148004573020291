import { createContext, useState } from "react";
import { ColorVariant } from "..";

interface Props {
  children?: React.ReactNode;
  color?: ColorVariant;
  className?: string;
}

interface AccordionContextType {
  openItem: null | string;
  setOpenItem: (id: string | null) => void;
}

const defaultContext: AccordionContextType = {
  openItem: null,
  setOpenItem: () => {},
};

export const AccordionContext = createContext(defaultContext);

export const Accordion = (props: Props) => {
  const [openItem, setOpenItem] = useState<string | null>(null);

  return (
    <AccordionContext.Provider value={{ openItem, setOpenItem }}>
      <div className="accordion">{props.children}</div>
    </AccordionContext.Provider>
  );
};
