import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import facebookIcon from "../assets/img/facebook-white.png";
import istagramIcon from "../assets/img/instagram-white.png";
import logo from "../assets/img/logo-big.png";

import {
  ButtonDropdown,
  ButtonDropdownItem,
  DeviceSize,
  Link,
  NavbarItem,
  NavigationHeader,
} from "imagine-ui";
import { User } from "imagine-users";
import { useTranslation } from "react-i18next";
import {
  KeyPage,
  KeyPageUtils,
  LanguageUrlMap,
  Page,
  PageUrl,
  PagesApi,
  WebPage,
} from "imagine-pages";
import { SnippetContentView } from "snippets";
import ImagineConfig from "../../../../ImagineConfig";
import { I18nText, I18nTools } from "imagine-i18n";

interface Props {
  user: User;
  keyPages: KeyPage[];
}

const getAvailablePageLanguages = (keyId: number, keyPages: KeyPage[]) => {
  const keyPage = keyPages.find((k) => k.id === keyId);
  if (!keyPage) return [];
  const navOptions = keyPage.pages.map((page: Page) => {
    return {
      text: I18nText.getLanguageText(page.language),
      to: KeyPageUtils.getFullPageUrl(page),
    } as NavbarItem;
  });
  return navOptions.sort((a, b) => {
    return (a.text || "").localeCompare(b.text || "");
  });
};

export const Layout = (props: Props) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [ready, setReady] = useState(false);

  const language = useMemo(() => {
    const urlLanguage = PageUrl.getLanguageFromUrl(location.pathname);

    // Convert to language codes that are supported by the API
    const lan = I18nTools.getLanguageFromUrlPrefix(urlLanguage);
    if (lan !== i18n.language) {
      i18n.changeLanguage(lan);
    }
    return lan;
  }, [i18n, location.pathname]);

  const pageUrl = useMemo(() => {
    return PageUrl.getPageUrl(location.pathname);
  }, [location.pathname]);

  const loadPageContent = useCallback(async () => {
    const response = await PagesApi.getPageContent(language, pageUrl);
    if (response.success) {
      setPage(response.data);
    }
    setReady(true);
  }, [language, pageUrl]);

  useEffect(() => {
    loadPageContent();
  }, [loadPageContent]);

  // const activePage = useMemo(() => {
  //   const pageUrlSegments = pageUrl.split("/");
  //   if(pageUrlSegments.length > 0) {
  //     return pageUrlSegments[0];
  //   }
  //   return "";
  // }, [pageUrl]);

  const year = useMemo(() => {
    const today = new Date();
    return today.getFullYear();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pageUrl]);

  const navigationItems = useMemo(() => {
    const items: NavbarItem[] = [
      {
        text: KeyPageUtils.getPageTitle(3, props.keyPages, language),
        to: KeyPageUtils.getPageUrl(3, props.keyPages, language),
      },
      {
        text: KeyPageUtils.getPageTitle(2, props.keyPages, language),
        to: KeyPageUtils.getPageUrl(2, props.keyPages, language),
      },

      {
        text: KeyPageUtils.getPageTitle(4, props.keyPages, language),
        to: KeyPageUtils.getPageUrl(4, props.keyPages, language),
      },
    ];
    if (props.user.id > 0) {
      items.push({ text: t("start"), href: "/app/", layout: "button" });
    } else {
      items.push({
        text: t("users:login"),
        href: "/app/login",
        outline: true,
        layout: "button",
      });
      items.push({
        text: t("users:createAccount"),
        href: "/app/sign-up",
        layout: "button",
      });
    }
    if (page !== undefined && page.keyId !== undefined) {
      items.push({
        icon: "globe",
        layout: "button",
        transparent: true,
        children: getAvailablePageLanguages(page.keyId, props.keyPages),
      });
    }
    return items;
  }, [language, page, props.keyPages, props.user.id, t]);

  return (
    <>
      <NavigationHeader items={navigationItems} collapseSize={DeviceSize.LG}>
        <img
          src={logo}
          className="logo me-4"
          alt="Garden Sketcher logo"
          height="48px"
        />
        <Link to={KeyPageUtils.getPageUrl(1, props.keyPages, language)}>
          <span className="text-accent">Garden </span>
          <span className="text-highlight">Sketcher</span>
        </Link>
      </NavigationHeader>

      <WebPage
        page={page}
        loading={!ready}
        language={language}
        SnippetContentViewComponent={SnippetContentView}
        snippetProps={{ user: props.user }}
      />
      <div className="footer-block">
        <div className="content">
          <div className="grid gap columns-3 columns-1--md text-center text-left--md">
            <div className="">
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(5, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(5, props.keyPages, language)}
              </Link>
              <br />
              {/* <a href="/planned-features">Planlagte funktioner</a><br />  */}
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(6, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(6, props.keyPages, language)}
              </Link>
              <br />
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(7, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(7, props.keyPages, language)}
              </Link>
              <br />
            </div>
            <div className="col-12 col-md-4 text-sm-start text-md-center">
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(8, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(8, props.keyPages, language)}
              </Link>
              <br />
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(9, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(9, props.keyPages, language)}
              </Link>
              <br />
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(10, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(10, props.keyPages, language)}
              </Link>
              <br />
              {/* <a href="#">Rapporter fejl</a>  */}
            </div>
            <div className="col-12 col-md-4 text-sm-start text-md-center">
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(11, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(11, props.keyPages, language)}
              </Link>
              <br />
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(12, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(12, props.keyPages, language)}
              </Link>
              <br />
              <Link
                className="link-light"
                to={KeyPageUtils.getPageUrl(13, props.keyPages, language)}
              >
                {KeyPageUtils.getPageTitle(13, props.keyPages, language)}
              </Link>
              <br />
              {/* <a href="#">Hjælp og videoer</a><br /> 
           <a href="#">Haveforum</a><br /> 
           <a href="#">Inspiration</a><br /> 
           <a href="#">Referencer</a>  */}
            </div>
          </div>
          <div className="text-center mt-xl">
            <a href="https://www.facebook.com/gardensketcher">
              <img src={facebookIcon} width="24px" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/garden_sketcher/"
              className="ml-sm"
            >
              <img src={istagramIcon} width="24px" alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
      <footer className="text-white text-center">
        <small>Garden Sketcher &copy; {year}</small>
      </footer>
    </>
  );
};
