import { useCallback, useEffect } from "react";

import { t } from "i18next";
import { useParams } from "react-router-dom";
import { MessageBoxGS } from "../components";
import { MembershipsTools } from "imagine-memberships";
import { Spinner } from "imagine-ui";

export const DealSaverContainer = () => {
  const params = useParams();

  const storeDealToken = useCallback(() => {
    if (params.token !== undefined) {
      MembershipsTools.storeDealToken(params.token);
      window.location.replace("/app/buy");
    }
  }, [params.token]);

  useEffect(() => {
    storeDealToken();
  }, [storeDealToken]);

  return (
    <MessageBoxGS title={t("lookingForSpecialDeal")}>
      <div className="text-center">
        <Spinner size="lg" />
      </div>
    </MessageBoxGS>
  );
};
