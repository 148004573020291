import { Delimiter, SentryReporter } from "imagine-essentials";
import { Countries, Country, Language } from "..";
import ImagineConfig from "../../../../../ImagineConfig";

const getBrowserLanguage = () => {
  if (window.navigator.language) return navigator.language;
  return ImagineConfig.defaultLanguage;
};

const getSupportedLanguages = () => {
  return ImagineConfig.supportedLanguages as Language[];
};

const getSupportedCountries = () => {
  return ImagineConfig.supportedCountries as Country[];
};

/**
 * Ensures that the returned language is valid. Will return the default language, if passed
 * language is not supported.
 * @param language
 * @returns
 */
const getValidLanguage = (language: string) => {
  let sanitizedLanguage = language;
  if (language === "da-DK") sanitizedLanguage = "da";
  if (ImagineConfig.supportedLanguages.includes(sanitizedLanguage))
    return sanitizedLanguage;
  return ImagineConfig.defaultLanguage;
};

/**
 * Get the language tag that for the selected user language. Uses BCP-47 format that is supported by
 * the Intl API.
 * @param language
 * @returns
 */
const getLanguageTag = (language: string) => {
  switch (language) {
    case "da":
      return "da-DK";
    case "en-GB":
      return "en-GB";
    default:
      return [];
  }
};

const getLanguageUrlPrefix = (language: Language) => {
  const languageUrl = ImagineConfig.languageUrlMap.find(
    (languageUrl) => languageUrl.language === language
  );
  if (!languageUrl) {
    console.error("No language URL map found for language: " + language);
    return "";
  }
  return languageUrl.urlPrefix;
};

const getLanguageFromUrlPrefix = (urlPrefix: string) => {
  const languageUrl = ImagineConfig.languageUrlMap.find(
    (languageUrl) => languageUrl.urlPrefix === urlPrefix
  );
  if (!languageUrl) {
    console.error("No language URL map found for URL prefix: " + urlPrefix);
    return ImagineConfig.defaultLanguage as Language;
  }
  return languageUrl.language as Language;
};

/**
 * Get the iso code of the country.
 * @param country
 * @returns
 */
const getCountryIsoCode = (country: Country) => {
  const code = Countries.find((c) => c.country === country);
  if (code === undefined) {
    console.error("Country not supported: " + country);
    return "208";
  }
  return code.isoCode;
};

const getCountry = (isoCode: string) => {
  const country = Countries.find((c) => c.isoCode === isoCode);
  if (country === undefined) {
    console.error("ISO code not supported: " + isoCode);
    return "DK";
  }
  return country.country;
};

/**
 * Get the country of the user based on their IP address.
 * @param fallbackCountry Fallback country in case user country is not supported. IF not set, the user country will be returned regardsless of support.
 * @returns
 */
const getUserCountry = async (fallbackCountry?: Country) => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const json = await response.json();
    if (json.country) {
      const supportedCountries = ImagineConfig.supportedCountries;
      if (supportedCountries.includes(json.country)) {
        return json.country as Country;
      } else {
        if (fallbackCountry) return fallbackCountry;
        return json.country;
      }
    }
    return fallbackCountry;
  } catch (e) {
    console.error("Could not get user location: ", e);
    SentryReporter.captureException(e);
    return fallbackCountry;
  }
};

const getUserLocationInfo = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    return await response.json();
  } catch (e) {
    console.error("Could not get user location: ", e);
    SentryReporter.captureException(e);
    return {};
  }
};

const isCountrySupported = (country: string) => {
  return ImagineConfig.supportedCountries.includes(country as Country);
};

const getDelimiter = (language: Language): Delimiter => {
  switch (language) {
    case "da":
      return ",";
    default:
      return ".";
  }
};

export const I18nTools = {
  getBrowserLanguage,
  getSupportedLanguages,
  getSupportedCountries,
  getValidLanguage,
  getLanguageTag,
  getLanguageUrlPrefix,
  getLanguageFromUrlPrefix,
  getCountryIsoCode,
  getCountry,
  getUserCountry,
  getUserLocationInfo,
  isCountrySupported,
  getDelimiter,
};
