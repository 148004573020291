import i18next from "i18next";
import { DateRange, DateTimeFormat } from "..";
import { I18nTools } from "imagine-i18n";

/**
 * Get the short text representation for a month.
 * @param value The month number, eg 1 for january
 */
const getMonthTextShort = (value: number) => {
  switch (value) {
    case 1:
      return i18next.t("datetime:januaryShort");
    case 2:
      return i18next.t("datetime:februaryShort");
    case 3:
      return i18next.t("datetime:marchShort");
    case 4:
      return i18next.t("datetime:aprilShort");
    case 5:
      return i18next.t("datetime:mayShort");
    case 6:
      return i18next.t("datetime:juneShort");
    case 7:
      return i18next.t("datetime:julyShort");
    case 8:
      return i18next.t("datetime:augustShort");
    case 9:
      return i18next.t("datetime:septemberShort");
    case 10:
      return i18next.t("datetime:octoberShort");
    case 11:
      return i18next.t("datetime:novemberShort");
    case 12:
      return i18next.t("datetime:decemberShort");
    default:
      throw new Error("Month out of range:" + value);
  }
};

/**
 * Get the full text representation of a month
 * @param value The month number, eg 1 for january
 */
const getMonthTextFull = (value: number) => {
  switch (value) {
    case 1:
      return i18next.t("datetime:january");
    case 2:
      return i18next.t("datetime:february");
    case 3:
      return i18next.t("datetime:march");
    case 4:
      return i18next.t("datetime:april");
    case 5:
      return i18next.t("datetime:may");
    case 6:
      return i18next.t("datetime:june");
    case 7:
      return i18next.t("datetime:july");
    case 8:
      return i18next.t("datetime:august");
    case 9:
      return i18next.t("datetime:september");
    case 10:
      return i18next.t("datetime:october");
    case 11:
      return i18next.t("datetime:november");
    case 12:
      return i18next.t("datetime:december");
    default:
      throw new Error("Month out of range:" + value);
  }
};

const getMonthTextFullLower = (month: number) => {
  const text = getMonthTextFull(month);
  return text.toLowerCase();
};

/**
 * Get a text representation of a list of months (full month names). The months are separated by a comma.
 * @param months
 * @returns
 */
const getMonthListText = (months: number[]) => {
  let monthStrings = "";
  months.forEach((month: number) => {
    if (monthStrings === "") monthStrings = getMonthTextFull(month);
    else monthStrings += ", " + getMonthTextFullLower(month);
  });
  if (monthStrings === "") return "";
  return monthStrings;
};

/**
 * Get a text representation of the months (full month names). If there is multiple months, only the first and last
 * month is included, separated by a dash.
 */
const getMonthListTextShort = (months: number[]) => {
  let monthStrings = "";
  months.forEach((month: number, index: number) => {
    if (monthStrings === "") {
      monthStrings = getMonthTextFull(month);
    } else {
      // Next month is also included
      if (
        months.length >= index + 1 &&
        months[index + 1] === month + 1 &&
        months[index - 1] === month - 1
      ) {
        if (monthStrings[monthStrings.length - 1] !== "-") monthStrings += "-";
      } else {
        if (monthStrings[monthStrings.length - 1] !== "-") {
          // Dash if previous month was right before this, and comma otherwise (month gap)
          if (months[index - 1] === month - 1) monthStrings += "-";
          else monthStrings += ", ";
        }
        monthStrings += getMonthTextFullLower(month);
      }
    }
  });
  if (monthStrings === "") return "";
  return monthStrings;
};

/**
 * Converts a date into a string with a given format. Default is db format with date and time.
 * The date is displayed in the browser's time zone and formatted according to the user's language.
 * @param date The date object (expects UTC time)
 */
const getDateTimeText = (
  date: Date,
  format: DateTimeFormat = DateTimeFormat.BACKEND_DATE_AND_TIME
) => {
  try {
    let options: Intl.DateTimeFormatOptions;
    let formatter: Intl.DateTimeFormat;
    const locale = i18next.language
      ? I18nTools.getLanguageTag(i18next.language)
      : [];

    switch (format) {
      case DateTimeFormat.BACKEND_DATE: {
        const m = date.getMonth() + 1.0;
        const d = date.getDate();
        return (
          date.getFullYear() +
          "-" +
          (m < 10 ? "0" : "") +
          m +
          "-" +
          (d < 10 ? "0" : "") +
          d
        );
      }
      case DateTimeFormat.SHORT_DATE_NO_YEAR:
        return date.getDate() + "/" + (date.getMonth() + 1.0);
      case DateTimeFormat.LONG_DATE:
        options = { dateStyle: "long" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      case DateTimeFormat.SHORT_DATE:
        options = { dateStyle: "short" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      case DateTimeFormat.DATE_AND_TIME:
        options = { dateStyle: "long", timeStyle: "short" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      case DateTimeFormat.SHORT_DATE_AND_TIME:
        options = { dateStyle: "short", timeStyle: "short" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      case DateTimeFormat.LONG_DATE_AND_TIME:
        options = { dateStyle: "long", timeStyle: "short" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      case DateTimeFormat.SHORT_TIME:
        options = { timeStyle: "short" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      case DateTimeFormat.LONG_TIME:
        options = { timeStyle: "long" };
        // Empty array as locale will select the browser locale
        formatter = new Intl.DateTimeFormat(locale, options);
        return formatter.format(date);
      default:
        throw new Error(
          "Missing implementation for date time display: " + format
        );
    }
  } catch (e) {
    console.error(e);
    return "";
  }
};

/**
 * Returns a pretty formatted date.
 * The date is displayed in the browser's time zone and formatted according to the user's language.
 * @param date The date in DB format
 * @param format The format to use on return date.
 */
const getDbDateTimeText = (date: string, format: DateTimeFormat) => {
  if (date === "") return "";
  // All dates from backend are in UTC
  const dateType = new Date(date + " UTC");
  return getDateTimeText(dateType, format);
};

const getTimestampDateTimeText = (
  timestamp: number,
  format: DateTimeFormat
) => {
  const date = new Date(timestamp * 1000);
  return getDateTimeText(date, format);
};

const getDateRangeText = (range: DateRange) => {
  switch (range) {
    case DateRange.CUSTOM:
      return i18next.t("datetime:custom");
    case DateRange.LAST_7_DAYS:
      return i18next.t("datetime:last7Days");
    case DateRange.LAST_30_DAYS:
      return i18next.t("datetime:last30Days");
    case DateRange.LAST_3_MONTHS:
      return i18next.t("datetime:last3Months");
    case DateRange.LAST_6_MONTHS:
      return i18next.t("datetime:last6Months");
    case DateRange.LAST_YEAR:
      return i18next.t("datetime:lastYear");
    case DateRange.NONE:
      return i18next.t("imagine:none");
    default:
      throw new Error("Unknown date range:" + range);
  }
};

export const DateTimeText = {
  getMonthTextShort,
  getMonthTextFull,
  getMonthTextFullLower,
  getMonthListText,
  getMonthListTextShort,
  getDateTimeText,
  getDbDateTimeText,
  getTimestampDateTimeText,
  getDateRangeText,
};
