import { useMemo } from "react";
import { ColorVariant } from "..";

interface Props {
  children?: React.ReactNode;
  color?: ColorVariant;
  className?: string;
  elementId?: string;
}

export const Alert = (props: Props) => {
  const className = useMemo(() => {
    let className = "alert";

    if (props.color) {
      className += " alert-" + props.color;
    } else {
      className += " spinner-dark";
    }
    if (props.className) className += " " + props.className;
    return className;
  }, [props.className, props.color]);

  return (
    <div className={className} id={props.elementId}>
      {props.children}
    </div>
  );
};
