import { SnippetBaseProps } from "imagine-pages";
import { UserRole } from "imagine-users";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Country, CurrencyTools, I18nTools } from "imagine-i18n";
import {
  DisplayProduct,
  MembershipProduct,
  MembershipProductCard,
  MembershipsTools,
  SubscriptionDataApi,
  SubscriptionProduct,
  SubscriptionProductCard,
  SubscriptionTools,
} from "imagine-memberships";
import { MembershipProductTemplates } from "project";
import { useTranslation } from "react-i18next";

interface PricesProps extends SnippetBaseProps {
  userRole?: UserRole;
}

export const Prices = (props: PricesProps) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState<Country | undefined>(undefined);

  const getCountry = useCallback(async () => {
    const c = (await I18nTools.getUserCountry("DK")) as any as Country;
    console.log("Country:", c);
    setCountry(c);
  }, []);

  const [subscriptionProducts, setSubscriptionProducts] = useState<
    SubscriptionProduct[]
  >([]);

  const getStripeMembershipProducts = useCallback(async () => {
    const response = await SubscriptionDataApi.getActiveProducts();
    if (response.success && response.data) {
      setSubscriptionProducts(response.data);
    }
  }, []);

  useEffect(() => {
    getStripeMembershipProducts();
  }, [getStripeMembershipProducts]);

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  /**
   * The text on the buttons is always targeted non-users, but when clicked any existing user is brought
   * directly to the buy section of their account page.
   * @param product
   */
  const selectProduct = (product: DisplayProduct) => {
    if (props.userRole === UserRole.NONE) {
      window.location.href = "/app/sign-up";
    } else {
      if (product.pricing[0].amount > 0) {
        window.location.href = "/app/buy";
      } else {
        window.location.href = "/app/sign-up";
      }
    }
  };

  const displayProducts = useMemo(() => {
    if (country === undefined) return [];
    const currency = CurrencyTools.getCountryCurrency(country as Country);
    const allProducts = SubscriptionTools.getDisplayProducts(
      subscriptionProducts,
      [],
      currency,
      true
    );

    // Add buttons
    return allProducts.map((product) => {
      return {
        ...product,
        buttonText:
          product.pricing[0].amount > 0
            ? t("memberships:buy")
            : t("users:createAccount"),
        onButtonClick: () => selectProduct(product),
      };
    });
  }, [country, selectProduct, subscriptionProducts, t]);

  const membershipProducts = useMemo(() => {
    if (country === undefined) return [];
    const countryProducts = MembershipsTools.getMembershipProducts(
      MembershipProductTemplates,
      country
    );
    const p = MembershipsTools.getAvailableMembershipProducts(
      countryProducts,
      UserRole.NONE
    );

    return p;
  }, [country]);

  return (
    <div className="snippet-content content-width grid gap columns-3 columns-2--lg columns-1--md">
      {displayProducts.map((product: DisplayProduct, index: number) => (
        <div className="h-full row items-center" key={index.toString()}>
          {/* <MembershipProductCard
            membershipProduct={product}
            onButtonClick={() => selectProduct()}
            elementId={product.titleKey}
          /> */}
          <SubscriptionProductCard product={product} elementId={product.id} />
        </div>
      ))}
    </div>
  );
};
