import { SemanticVersion } from "..";

const getAppVersion = () => {
  const version = process.env.VERSION || "";
  return version;
};

const getSemanticVersion = (version: string) => {
  const versionParts = version.split("-");
  const semanticVersion: SemanticVersion = {
    major: 0,
    minor: 0,
    patch: 0,
  };

  if (versionParts.length > 0) {
    const versionNumbers = versionParts[0].split(".");
    if (versionNumbers.length < 2) {
      throw new Error("Invalid version string");
    }
    semanticVersion.major = Number(versionNumbers[0]);
    semanticVersion.minor = Number(versionNumbers[1]);
    if (versionNumbers.length > 2) {
      semanticVersion.patch = Number(versionNumbers[2]);
    }
  }
  if (versionParts.length > 1) {
    semanticVersion.preRelease = versionParts[1];
  }
  return semanticVersion;
};

/**
 * Check if a version is older than a current version.
 * @param version Version to test
 * @param currentVersion Current version
 * @param includePatch Include patch version in comparison
 * @returns True if version is older than current version
 */
const isVersionOlder = (
  version: string,
  currentVersion: string,
  includePatch?: boolean
) => {
  const old = getSemanticVersion(version);
  const current = getSemanticVersion(currentVersion);

  if (old.major < current.major) return true;
  if (old.major > current.major) return false;
  if (old.minor < current.minor) return true;
  if (old.minor > current.minor) return false;
  if (includePatch) {
    if (old.patch < current.patch) return true;
    if (old.patch > current.patch) return false;
  }
  return false;
};

export const AppInfo = {
  getAppVersion,
  isVersionOlder,
};
