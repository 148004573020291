import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AccordionContext, Icon } from "..";

interface Props {
  id: string;
  title: string;
  children: React.ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
}

export const AccordionItem = (props: Props) => {
  const { openItem, setOpenItem } = useContext(AccordionContext);
  const [height, setHeight] = useState(0);
  const bodyRef = useRef<HTMLDivElement>(null);

  const isOpen = openItem === props.id;
  const toggleOpen = () => {
    setOpenItem(isOpen ? null : props.id);
  };

  useEffect(() => {
    if (bodyRef.current) {
      const rect = bodyRef.current.getBoundingClientRect();
      setHeight(Math.max(bodyRef.current.scrollHeight, rect.height));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyRef.current]);

  const collapsibleStyle = useMemo(() => {
    return {
      maxHeight: isOpen ? height + "px" : "0",
    };
  }, [height, isOpen]);

  const headerClass = useMemo(() => {
    let className = "accordion-header";
    if (isOpen) {
      className += " open";
    }
    if (props.headerClassName) {
      className += " " + props.headerClassName;
    }
    return className;
  }, [isOpen, props.headerClassName]);

  const bodyClass = useMemo(() => {
    let className = "accordion-collapsible";
    if (isOpen) {
      className += " open";
    }
    if (props.bodyClassName) {
      className += " " + props.bodyClassName;
    }
    return className;
  }, [isOpen, props.bodyClassName]);

  return (
    <div className="accordion-item">
      <button className={headerClass} onClick={toggleOpen}>
        <span className="text">{props.title}</span>
        <Icon
          className={"icon" + (isOpen ? " open" : "")}
          name="chevron-down"
        />
      </button>
      <div className={bodyClass} style={collapsibleStyle} ref={bodyRef}>
        <div className={"accordion-body"}>{props.children}</div>
      </div>
    </div>
  );
};
