import { Currency } from "imagine-i18n";
import {
  DisplayProduct,
  DisplayProductPricing,
  MembershipsText,
  Subscription,
  SubscriptionCoupon,
  SubscriptionInvoice,
  SubscriptionPrice,
  SubscriptionProduct,
} from "..";
import i18next from "i18next";

/**
 * Get a display product for a given currency by combining Stripe product, product details, Stripe coupons and coupon details.
 * @param subscriptionProduct
 * @param productDetails
 * @param coupon
 * @param couponDetails
 * @param currency
 * @returns
 */
const getDisplayProduct = (
  subscriptionProduct: SubscriptionProduct,
  coupons: SubscriptionCoupon[],
  currency: Currency
) => {
  // Only prices in selected currency
  if (subscriptionProduct.prices === undefined) return [];
  const subscriptionPrices = subscriptionProduct.prices.filter(
    (price) => price.currency === currency
  );

  const prices = subscriptionPrices.map((price: SubscriptionPrice) => {
    // Get coupon details that matches product name and price interval - return only ids
    const allowedCoupons = coupons.filter((coupon: SubscriptionCoupon) => {
      return coupon.priceId === price.id;
    });

    return {
      ...price,
      amount: price.amount / 100,
      coupons: allowedCoupons,
    } as DisplayProductPricing;
  });

  return {
    id: subscriptionProduct.stripeId,
    title: i18next.t(subscriptionProduct.titleKey),
    pricing: prices,
    features: subscriptionProduct.featureKeys.map((key) => i18next.t(key)),
  } as DisplayProduct;
};

const getDisplayProducts = (
  subscriptionProducts: SubscriptionProduct[],
  coupons: SubscriptionCoupon[],
  currency: Currency,
  expandPrices: boolean = false
) => {
  // const prices = stripeProducts.
  const products = subscriptionProducts
    .map((stripeProduct) => {
      return getDisplayProduct(stripeProduct, coupons, currency);
    })
    .filter((product) => product !== undefined) as DisplayProduct[];

  if (expandPrices) {
    const expandedProducts: DisplayProduct[] = [];
    products.forEach((product: DisplayProduct) => {
      product.pricing.forEach((pricing: DisplayProductPricing) => {
        let interval =
          " (" +
          MembershipsText.getProductIntervalTitleText(pricing.billingInterval) +
          ")";
        if (interval === " ()") interval = "";
        expandedProducts.push({
          id: product.id,
          title: product.title + interval,
          pricing: [pricing],
          features: product.features,
        });
      });
    });
    return expandedProducts;
  } else {
    return products;
  }
};

/**
 * Get the display product type based on an invoice
 * @param invoice
 * @param subscription
 * @returns
 */
const getInvoiceDisplayProduct = (
  invoice: SubscriptionInvoice,
  subscription: Subscription
) => {
  // Find coupons that match the invoice
  const availableCoupons: SubscriptionCoupon[] = [];
  if (invoice.coupon) {
    availableCoupons.push(invoice.coupon);
  }

  // Only include "forever" coupons from subscription
  if (subscription.coupons) {
    subscription.coupons.forEach((coupon) => {
      if (coupon.duration === "forever") {
        availableCoupons.push(coupon);
      }
    });
  }

  if (invoice.price === undefined) return undefined;
  if (subscription.product === undefined) return undefined;

  const pricing = {
    ...invoice.price,
    amount: invoice.price.amount / 100,
    coupons: availableCoupons,
  } as DisplayProductPricing;

  const interval = MembershipsText.getProductIntervalTitleText(
    pricing.billingInterval
  );

  return {
    id: invoice.id.toString(),
    title: i18next.t(subscription.product.titleKey) + " (" + interval + ")",
    pricing: [pricing],
    features: subscription.product.featureKeys.map((key) => i18next.t(key)),
  } as DisplayProduct;
};

export const SubscriptionTools = {
  getDisplayProducts,
  getInvoiceDisplayProduct,
};
