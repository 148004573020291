const getLanguageFromUrl = (url: string): string => {
  const path = url.replace(/^\/|\/$/g, "");
  const segments = path.split("/");
  if (segments.length > 0 && /^[a-z]{2}$/.test(segments[0])) {
    return segments[0];
  }
  return "";
};

const getPageUrl = (url: string): string => {
  const path = url.replace(/^\/|\/$/g, "");
  const segments = path.split("/");
  if (segments.length > 0 && /^[a-z]{2}$/.test(segments[0])) {
    segments.shift();
  }
  return segments.join("/");
};

export const PageUrl = {
  getLanguageFromUrl,
  getPageUrl,
};
