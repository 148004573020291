import { useParams } from "react-router-dom";

import { t } from "i18next";

import { Trans } from "react-i18next";
import { MessageBoxGS } from "../components";
import { useCallback, useEffect, useState } from "react";
import { UsersApi } from "imagine-users";
import { Alert, Link, Spinner } from "imagine-ui";

export const ActivateAccountContainer = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [activated, setActivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const activationCode = params.activationCode ?? "";
  const language = params.language ?? "en";

  const gettingStartedUrl =
    language === "en" ? "/getting-started" : "/da/kom-i-gang";

  const activate = useCallback(async () => {
    setLoading(true);
    const response = await UsersApi.activateAccount(activationCode);
    if (response.success) {
      setActivated(true);
    } else if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    }
    setLoading(false);
  }, [activationCode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      activate();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [activate]);

  return (
    <MessageBoxGS title={t("users:activation")}>
      {loading && (
        <div className="text-center">
          <Spinner size="lg" />
        </div>
      )}
      {!loading && errorMessage && (
        <Alert color="danger" elementId="activation-failed">
          {errorMessage}
        </Alert>
      )}
      {activated && (
        <>
          <h5 className="mb" id="activation-successfull">
            {t("yourAccountIsReady")}
          </h5>
          <p>
            <Trans
              i18nKey="activationGettingStartedMessage"
              components={{
                link1: <Link href="/app/login" />,
                link2: <Link to={gettingStartedUrl} />,
              }}
            />
          </p>
          <p>{t("enjoy")}</p>
        </>
      )}
    </MessageBoxGS>
  );
};
