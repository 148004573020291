export enum ContentTypes {
  NONE = 0,
  TEXT = 1,
  IMAGE = 2,
  VIDEO = 3,
  TEXT_AND_IMAGE = 4,
  SNIPPET = 5,
  CARDS = 6,
  ACCORDION = 7,
  NAVIGATION = 8,
}
