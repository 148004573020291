import { SnippetContentViewProps, withSnippetContentView } from "imagine-pages";
import { Prices } from "./Prices";
import { SnippetProps } from "../types/SnippetProps";

export const SnippetContentView = withSnippetContentView(
  (props: SnippetContentViewProps) => {
    const snippetProps = props.props as SnippetProps;

    return (
      <>
        {props.content.name === "prices" && (
          <Prices userRole={snippetProps.user?.role || undefined} />
        )}
      </>
    );
  }
);
