import { useMemo } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  CancelState,
  InvoiceInfo,
  Membership,
  MembershipDeal,
  MembershipProduct,
} from "..";
import { DateTimeFormat, DateTimeText, DateTimeTools } from "imagine-datetime";
import { useTranslation } from "react-i18next";
import { Tools } from "imagine-essentials";
import { CurrencyTools, I18nText } from "imagine-i18n";

// Create styles
const defaultFontSize = "10pt";
const smallFontSize = "8pt";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "2cm",
    paddingBottom: "1.5cm",
  },
  filler: {
    flex: 1,
  },
  filler2: {
    flex: 2,
  },
  row: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
  },
  banner: {
    backgroundColor: "#EEEEEE",
    position: "absolute",
    width: "10cm",
    height: "1.5cm",
    transform: "rotate(-45deg)",
    zIndex: "10",
    left: "-3cm",
    top: "1cm",
    paddingTop: "0.5cm",
    paddingBottom: "0.4cm",
  },
  bannerText: {
    textAlign: "center",
    fontSize: "12pt",
    textTransform: "uppercase",
  },
  logo: {
    right: "0cm",
    height: "4cm",
    flexBasis: "4cm",
  },
  customerInfo: {
    flexBasis: "6cm",
  },
  invoiceDetails: {
    flexBasis: "7cm",
  },
  header: {
    fontSize: "20pt",
    paddingTop: "0.4cm",
    paddingBottom: "0.4cm",
  },
  tableRowHeader: {
    flexDirection: "row",
    paddingTop: "0.4cm",
    paddingBottom: "0.4cm",
    borderBottom: "2pt solid black",
    borderTop: "2pt solid black",
  },
  tableRow: {
    flexDirection: "row",
    paddingTop: "0.4cm",
    paddingBottom: "0.4cm",
    borderBottom: "1pt solid black",
  },
  colDescription: {
    flex: 2,
    fontSize: defaultFontSize,
  },
  colDescriptionNote: {
    fontSize: smallFontSize,
    marginTop: "0.3cm",
  },
  colAmount: {
    flex: 1,
    fontSize: defaultFontSize,
    textAlign: "center",
  },
  colUnit: {
    flex: 1,
    fontSize: defaultFontSize,
    textAlign: "center",
  },
  colPcsPrice: {
    flex: 1,
    fontSize: defaultFontSize,
    textAlign: "right",
  },
  colPrice: {
    flex: 1,
    fontSize: defaultFontSize,
    textAlign: "right",
  },
  sumBlock: {
    flexBasis: "7cm",
  },
  totalPriceRow: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    borderBottom: "1pt solid black",
    borderTop: "1pt solid black",
    paddingTop: "0.4cm",
    fontWeight: "bold",
  },
  greeting: {
    textAlign: "center",
    fontSize: defaultFontSize,
  },
  footer: {
    textAlign: "center",
    fontSize: smallFontSize,
    paddingTop: "0.3cm",
  },
  textRight: {
    fontSize: defaultFontSize,
    textAlign: "right",
    paddingBottom: "0.2cm",
  },
  textLeft: {
    fontSize: defaultFontSize,
    textAlign: "left",
    paddingBottom: "0.2cm",
  },
});

/**
 * Get the invoice info that matches the date the membership was bought
 */
const getUsedInvoiceInfo = (
  invoiceInfoList: InvoiceInfo[],
  buyDate: string
) => {
  if (invoiceInfoList.length === 0) {
    throw new Error("Invoice list is empty");
  }
  const buyTimestamp = DateTimeTools.getDateFromDbFormat(buyDate).getTime();
  let index = 0;
  invoiceInfoList.forEach((invoice: InvoiceInfo, i: number) => {
    const invoiceStartTimestamp = DateTimeTools.getDateFromDbFormat(
      invoice.date
    ).getTime();
    if (buyTimestamp >= invoiceStartTimestamp) {
      index = i;
    }
  });
  return invoiceInfoList[index];
};

type Props = {
  membership: Membership;
  membershipProduct: MembershipProduct;
  membershipDeal?: MembershipDeal; // In case discount was given, this should be showed on the invoice
  invoiceInfoList: InvoiceInfo[];
};

// Create Document Component
export const Invoice = (props: Props) => {
  const { t } = useTranslation();
  const invoiceInfo = useMemo(() => {
    return getUsedInvoiceInfo(
      props.invoiceInfoList,
      props.membership.purchaseDate
    );
  }, [props.invoiceInfoList, props.membership]);

  const getBeforeVat = () => {
    if (invoiceInfo.vat < 0) {
      throw new Error("VAT cannot be negative");
    }
    const before = (props.membership.price * 100) / (invoiceInfo.vat + 100);
    return Tools.round(before, 2);
  };

  const getVatAmount = () => {
    const vat = props.membership.price - getBeforeVat();
    return vat;
  };

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.row}>
            <View style={styles.customerInfo}>
              <View style={styles.filler}></View>
              <Text style={styles.textLeft}>{props.membership.fullName}</Text>
              <Text style={styles.textLeft}>{props.membership.address1}</Text>
              {props.membership.address2 !== "" && (
                <Text style={styles.textLeft}>{props.membership.address2}</Text>
              )}
              <Text style={styles.textLeft}>
                {props.membership.postCode} {props.membership.city}
              </Text>
              <Text style={styles.textLeft}>
                {I18nText.getCountryText(props.membership.country)}
              </Text>
            </View>
            <View style={styles.filler}></View>
            <Image style={styles.logo} src={invoiceInfo.logoPath}></Image>
          </View>
          <View style={styles.filler2}></View>
          <View style={styles.row}>
            <View style={styles.filler}></View>
            <View style={styles.invoiceDetails}>
              <View style={styles.row}>
                <Text style={styles.textLeft}>
                  {t("memberships:invoiceNumber")}:
                </Text>
                <Text style={styles.textRight}>{props.membership.id}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.textLeft}>
                  {t("memberships:invoiceDate")}:
                </Text>
                <Text style={styles.textRight}>
                  {DateTimeText.getDbDateTimeText(
                    props.membership.purchaseDate,
                    DateTimeFormat.LONG_DATE
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.textLeft}>
                  {t("memberships:deliveryDate")}:
                </Text>
                <Text style={styles.textRight}>
                  {DateTimeText.getDbDateTimeText(
                    props.membership.startDate,
                    DateTimeFormat.LONG_DATE
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.filler}></View>
          <Text style={styles.header}>{t("memberships:invoice")}</Text>
          <View style={styles.tableRowHeader}>
            <Text style={styles.colDescription}>
              {t("imagine:description")}
            </Text>
            <Text style={styles.colAmount}>{t("memberships:amount")}</Text>
            <Text style={styles.colUnit}>{t("memberships:unit")}</Text>
            <Text style={styles.colPcsPrice}>{t("memberships:pcsPrice")}</Text>
            <Text style={styles.colPrice}>{t("memberships:price")}</Text>
          </View>
          {/* The membership product */}
          <View style={styles.tableRow}>
            <Text style={styles.colDescription}>
              {t(props.membership.titleKey)}
              {props.membership.expiryDate !== null && (
                <Text style={styles.colDescriptionNote}>
                  {"\n"}
                  {t("memberships:forPeriod", {
                    startDate: DateTimeText.getDbDateTimeText(
                      props.membership.startDate,
                      DateTimeFormat.SHORT_DATE
                    ),
                    endDate: DateTimeText.getDbDateTimeText(
                      props.membership.expiryDate,
                      DateTimeFormat.SHORT_DATE
                    ),
                  })}
                </Text>
              )}
            </Text>

            <Text style={styles.colAmount}>1</Text>
            <Text style={styles.colUnit}>{t("memberships:pcs")}</Text>
            <Text style={styles.colPcsPrice}>
              {CurrencyTools.getDisplayPrice(
                props.membershipProduct.price,
                props.membership.currency,
                true
              )}
            </Text>
            <Text style={styles.colPrice}>
              {CurrencyTools.getDisplayPrice(
                props.membershipProduct.price,
                props.membership.currency,
                true
              )}
            </Text>
          </View>
          {/* Membership deal */}
          {props.membershipDeal && (
            <View style={styles.tableRow}>
              <Text style={styles.colDescription}>
                {props.membershipDeal.name}
                <Text style={styles.colDescriptionNote}>
                  {"\n"}
                  {props.membershipDeal.description}
                </Text>
              </Text>

              <Text style={styles.colAmount}>1</Text>
              <Text style={styles.colUnit}>{t("memberships:pcs")}</Text>
              <Text style={styles.colPcsPrice}>
                {CurrencyTools.getDisplayPrice(
                  props.membershipProduct.price *
                    props.membershipDeal.discount *
                    -1,
                  props.membership.currency,
                  true
                )}
              </Text>
              <Text style={styles.colPrice}>
                {CurrencyTools.getDisplayPrice(
                  props.membershipProduct.price *
                    props.membershipDeal.discount *
                    -1,
                  props.membership.currency,
                  true
                )}
              </Text>
            </View>
          )}

          <View style={styles.filler2}></View>
          <View style={styles.row}>
            <View style={styles.filler}></View>
            <View style={styles.sumBlock}>
              <View style={styles.row}>
                <Text style={styles.textLeft}>
                  {t("memberships:totalExVat")}
                </Text>
                <Text style={styles.textRight}>
                  {CurrencyTools.getDisplayPrice(
                    getBeforeVat(),
                    props.membership.currency,
                    true
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.textLeft}>
                  {t("memberships:vat")} ({invoiceInfo.vat}%)
                </Text>
                <Text style={styles.textRight}>
                  {CurrencyTools.getDisplayPrice(
                    getVatAmount(),
                    props.membership.currency,
                    true
                  )}
                </Text>
              </View>
              <View style={styles.totalPriceRow}>
                <Text style={styles.textLeft}>
                  {t("memberships:totalInclVat")}
                </Text>
                <Text style={styles.textRight}>
                  {CurrencyTools.getDisplayPrice(
                    props.membership.price,
                    props.membership.currency,
                    true
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.filler2}></View>
          {/* <Text style={styles.greeting}>
            {t("users:thankYouForUsing")} {invoiceInfo.productName}
          </Text> */}
          <View style={styles.filler2}></View>
          {/* <View style={styles.row}> */}
          <Text style={styles.footer}>
            {invoiceInfo.companyName} &nbsp; &bull; &nbsp;{" "}
            {invoiceInfo.companyAddress} &nbsp; &bull; &nbsp;{" "}
            {invoiceInfo.companyCity} &nbsp; &bull; &nbsp;{" "}
            {invoiceInfo.companyCountry}
          </Text>
          <Text style={styles.footer}>
            CVR. {invoiceInfo.companyCVR} &nbsp; &bull; &nbsp;{" "}
            {invoiceInfo.companyWebsite} &nbsp; &bull; &nbsp;{" "}
            {invoiceInfo.companyEmail}
          </Text>
          <View style={styles.banner}>
            <Text style={styles.bannerText}>
              {props.membership.cancelled === CancelState.NOT_CANCELLED &&
                t("memberships:payed")}
              {props.membership.cancelled ===
                CancelState.PAYMENT_NOT_COMPLETED && t("memberships:cancelled")}
              {props.membership.cancelled === CancelState.REFUNDED &&
                t("memberships:refunded")}
            </Text>
          </View>
          {/* </View> */}
        </Page>
      </Document>
    </>
  );
};
