import { Api } from "imagine-essentials";
import { Spinner } from "imagine-ui";
import { UserRole, UserSelector } from "imagine-users";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../store/store";

export const InvoiceContainer = () => {
  const { t } = useTranslation();
  const params = useParams();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const loadInvoice = useCallback(async () => {
    // if (user.role === UserRole.NONE) return;
    if (params.invoiceId) {
      setLoading(true);
      const result = await Api.get(
        "api/pdf-invoice/" + params.invoiceId + "/" + user.language
      );
      setLoading(false);
      if (result.success) {
        const url = window.URL.createObjectURL(result.data);
        setPdfUrl(url);
      }
    }
  }, [params.invoiceId, user]);

  useEffect(() => {
    loadInvoice();
  }, [loadInvoice]);

  return (
    <>
      {loading && !pdfUrl && (
        <div className="w-full h-full-screen row items-center items-y-center">
          <Spinner />
        </div>
      )}
      {pdfUrl && !loading && (
        <iframe src={pdfUrl} className="pdf-iframe" title="PDF Viewer"></iframe>
      )}
      {!pdfUrl && !loading && (
        <div className="w-full h-full-screen row items-center items-y-center">
          {t("memberships:invoiceNotFoundOrNoAccess")}
        </div>
      )}
    </>
  );
};
