import { MessageBox } from "imagine-ui";

import logo from "../assets/img/logo-big.png";

interface Props {
  children: React.ReactNode;
  title: string;
}

export const MessageBoxGS = (props: Props) => {
  const renderBrand = () => {
    return (
      <div className=" row">
        <img src={logo} alt="logo" className="logo mr" />
        <span className="text-accent mr-xs">Garden</span>
        <span className="text-highlight">Sketcher</span>
      </div>
    );
  };
  return (
    <MessageBox brand={renderBrand()} title={props.title}>
      {props.children}
    </MessageBox>
  );
};
