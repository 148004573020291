import { useEffect, useMemo } from "react";
import { CardContentEntry, CardContentLayout, CardsContent } from "../..";
import { Api } from "imagine-essentials";

type Props = {
  content: CardsContent;
};

/**
 * Display a preview of the cards.
 */
export const CardsContentView = (props: Props) => {
  useEffect(() => {}, []);

  const cardClassName = useMemo(() => {
    let className = "content-card";
    if (props.content.layout === CardContentLayout.FLOATING)
      className += " floating";
    if (props.content.layout === CardContentLayout.BOXED) className += " boxed";
    return className;
  }, [props.content]);

  return (
    <div className={"cards-content cards-" + props.content.columns}>
      {props.content.entries.map((card: CardContentEntry, index: number) => (
        <div className={cardClassName} key={index.toString()}>
          <img
            src={Api.getHost() + card.image}
            className="fluid"
            alt={card.altText}
          />
          <div
            dangerouslySetInnerHTML={{ __html: card.html }}
            className="text"
          ></div>
        </div>
      ))}
    </div>
  );
};
