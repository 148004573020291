import { Country } from "..";

/**
 * Links between the ISO codes and short names of all countries supported in
 * Imagine projects. Add to this list (and the type files)
 */
export const Countries: { isoCode: string; country: Country }[] = [
  {
    isoCode: "208",
    country: "DK",
  },
  {
    isoCode: "372",
    country: "IE",
  },
  {
    isoCode: "826",
    country: "GB",
  },
];
