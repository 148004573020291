/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { NotificationType } from "..";

/**
 * The actual context that holds the data. These initial values will be overwritten
 * in the provider where the context data is actually stored.
 */
export const NotificationContext = React.createContext({
  type: NotificationType.NONE,
  message: "",
  add: (_type: NotificationType, _message: string) => {},
  onConfirm: (_c: () => void) => {},
  clear: () => {},
  confirm: () => {},
});
