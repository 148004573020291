import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BonusDays,
  CheckoutStep,
  Customer,
  DisplayProduct,
  Membership,
  MembershipDeal,
  MembershipThunks,
  MembershipsData,
  MembershipsTools,
  OrderStatus,
  Subscription,
  SubscriptionCustomer,
  SubscriptionInvoice,
  SubscriptionPaymentMethod,
} from "..";

import { RequestStatus } from "imagine-essentials";
import { Country } from "imagine-i18n";

interface MembershipState {
  status: RequestStatus;
  stripeStatus: RequestStatus;
  subscriptions: Subscription[];
  otherInvoices: SubscriptionInvoice[];
  paymentMethod?: SubscriptionPaymentMethod;
  customer?: SubscriptionCustomer; // The customer information used on active subscription
  // For checkout
  selectedDisplayProduct: DisplayProduct | null; // Using Strip procucts
  checkoutStep: CheckoutStep;
  customerInfo: Customer; // The customer info entered when buying new subscription
  purchaseInvoice: SubscriptionInvoice | null;
  clientSecret: string;
  orderStatus: OrderStatus;
  paymentUpdated: boolean;
  // Old membership data
  activeMembership: Membership | null;
  membershipHistory: Membership[];
  deals: MembershipDeal[];
  bonusDays: BonusDays[];
}

// All data related to the memberships
const initialState: MembershipState = {
  status: RequestStatus.IDLE,
  stripeStatus: RequestStatus.IDLE,
  subscriptions: [],
  otherInvoices: [],
  paymentMethod: undefined,
  selectedDisplayProduct: null,
  checkoutStep: CheckoutStep.SELECT_MEMBERSHIP, //.SELECT_MEMBERSHIP,
  customerInfo:
    MembershipsTools.getCustomerInfoFromLocalStorage() ||
    MembershipsData.getDefaultCustomer("DK"), // Country is replaced

  purchaseInvoice: null,
  clientSecret: "",
  orderStatus: OrderStatus.NONE,
  paymentUpdated: false,
  // Old membership data
  activeMembership: null,
  membershipHistory: [],
  deals: [],
  bonusDays: [],
};

export const membershipSlice = createSlice({
  name: "membership",
  initialState: initialState,
  reducers: {
    setSelectedDisplayProduct: (
      state,
      action: PayloadAction<DisplayProduct | null>
    ) => {
      state.selectedDisplayProduct = action.payload;
    },
    setCheckoutStep: (state, action: PayloadAction<CheckoutStep>) => {
      state.checkoutStep = action.payload;
    },
    setCustomerInfo: (state, action: PayloadAction<Customer>) => {
      state.customerInfo = action.payload;
      MembershipsTools.setCustomerInfoInLocalStorage(action.payload);
    },
    setCustomerCountry: (state, action: PayloadAction<string>) => {
      state.customerInfo.country = action.payload;
    },
    setPurchaseInvoice: (
      state,
      action: PayloadAction<SubscriptionInvoice | null>
    ) => {
      state.purchaseInvoice = action.payload;
    },

    setClientSecret: (state, action: PayloadAction<string>) => {
      state.clientSecret = action.payload;
    },

    setOrderStatus: (state, action: PayloadAction<OrderStatus>) => {
      state.orderStatus = action.payload;
    },
    finishMembershipLoading: (state) => {
      console.log("Finish old membership loading");
      state.status = RequestStatus.READY;
    },
    finishStripeMembershipLoading: (state) => {
      console.log("Finish Stripe membership loading");
      state.stripeStatus = RequestStatus.READY;
    },
    setPaymentUpdated: (state, action: PayloadAction<boolean>) => {
      state.paymentUpdated = action.payload;
    },
    clearMemberships: (state) => {
      console.log("Clear memberships");
      state.checkoutStep = CheckoutStep.SELECT_MEMBERSHIP;
      state.customerInfo = MembershipsData.getDefaultCustomer(
        state.customerInfo.country as Country
      );
      state.activeMembership = null;
      state.membershipHistory = [];
      state.deals = [];
      state.bonusDays = [];
      state.subscriptions = [];
      state.paymentMethod = undefined;
      state.paymentUpdated = false;
      state.selectedDisplayProduct = null;
      state.purchaseInvoice = null;
      state.clientSecret = "";
      state.orderStatus = OrderStatus.NONE;
      state.stripeStatus = RequestStatus.IDLE;
      state.status = RequestStatus.IDLE;
    },
    clearPurchase: (state) => {
      state.checkoutStep = CheckoutStep.SELECT_MEMBERSHIP;
      state.selectedDisplayProduct = null;
      state.purchaseInvoice = null;
      state.clientSecret = "";
      state.orderStatus = OrderStatus.NONE;
      state.paymentUpdated = false;
    },
    setMembershipStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status = action.payload;
    },
    // Old membership data
    setActiveMembership: (state, action: PayloadAction<Membership | null>) => {
      state.activeMembership = action.payload;
    },
    setMembershipHistory: (state, action: PayloadAction<Membership[]>) => {
      state.membershipHistory = action.payload;
    },
    setDeals: (state, action: PayloadAction<MembershipDeal[]>) => {
      state.deals = action.payload;
    },
    setBonusDays: (state, action: PayloadAction<BonusDays[]>) => {
      state.bonusDays = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(MembershipThunks.fetchUserSubscriptions.pending, (state) => {
        state.stripeStatus = RequestStatus.LOADING;
      })
      .addCase(
        MembershipThunks.fetchUserSubscriptions.fulfilled,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state, action: PayloadAction<any | null>) => {
          console.log("Received stripe membership data", action.payload);
          if (action.payload.success && action.payload.data) {
            state.subscriptions = action.payload.data.subscriptions || [];
            // state.otherInvoices = action.payload.data.otherInvoices || [];
            state.paymentMethod =
              action.payload.data.paymentMethod || undefined;
            state.customer = action.payload.data.customer || undefined;
            state.stripeStatus = RequestStatus.READY;
          } else {
            state.stripeStatus = RequestStatus.FAILED;
          }
        }
      )
      .addCase(MembershipThunks.fetchUserSubscriptions.rejected, (state) => {
        state.stripeStatus = RequestStatus.FAILED;
      })
      // Old membership data
      .addCase(MembershipThunks.fetchMembershipData.pending, (state) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(
        MembershipThunks.fetchMembershipData.fulfilled,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state, action: PayloadAction<any | null>) => {
          console.log("Received old membership data", action.payload);
          // if (action.payload.membership) {
          state.activeMembership = action.payload.membership || null;
          // }
          // if (action.payload.membershipHistory) {
          state.membershipHistory = action.payload.membershipHistory || [];
          // }
          // if (action.payload.deals) {
          state.deals = action.payload.deals || [];
          // }
          // if (action.payload.bonusDays) {
          state.bonusDays = action.payload.bonusDays || [];
          // }

          if (action.payload.errorMessage) {
            state.status = RequestStatus.FAILED;
          } else {
            state.status = RequestStatus.READY;
          }
        }
      )
      .addCase(MembershipThunks.fetchMembershipData.rejected, (state) => {
        state.status = RequestStatus.FAILED;
      });
  },
});

export const MembershipActions = membershipSlice.actions;

export const membershipReducer = membershipSlice.reducer;
