import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RestorePassword, UsersApi } from "imagine-users";
import { useState } from "react";
import { MessageBoxGS } from "../components";

export const RestorePasswordContainer = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const resetCode = params.resetCode ?? "";

  const restorePassword = async (password: string) => {
    setLoading(true);
    const response = await UsersApi.restorePassword(password, resetCode);
    if (response.success) {
      setMessage(t("users:passwordRestoredMessage"));
    } else if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    }
    setLoading(false);
  };

  return (
    <MessageBoxGS title={t("users:restorePassword")}>
      <RestorePassword
        onRestorePassword={restorePassword}
        loading={loading}
        errorMessage={errorMessage}
        message={message}
        loginUrl="/app/login"
      />
    </MessageBoxGS>
  );
};
