import { Option } from "imagine-essentials";
import { CancelReason, CancelState, Customer, MembershipsText } from "..";
import { Country, I18nTools } from "imagine-i18n";

const getCancelReasonOptions = () => {
  const options: Option[] = [];
  for (let i = 0; i < CancelReason._SIZE; i++) {
    options.push({
      value: i,
      label: MembershipsText.getCancelReasonText(i),
    } as Option);
  }
  return options;
};

const getCancelStateOptions = () => {
  const options: Option[] = [];
  for (let i = 1; i < CancelState._SIZE; i++) {
    options.push({
      value: i,
      label: MembershipsText.getCancelStateText(i),
    } as Option);
  }
  return options;
};

const getDefaultCustomer = (country: Country) => {
  return {
    name: "",
    address1: "",
    address2: "",
    postCode: "",
    city: "",
    country: country,
  } as Customer;
};

export const MembershipsData = {
  getCancelReasonOptions,
  getCancelStateOptions,
  getDefaultCustomer,
};
