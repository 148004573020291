import i18next from "i18next";

const getCountryText = (country: string) => {
  switch (country) {
    case "DK":
      return i18next.t("i18n:countryDK");
    case "GB":
      return i18next.t("i18n:countryGB");
    case "IE":
      return i18next.t("i18n:countryIE");
    case "NO":
      return i18next.t("i18n:countryNO");
    case "SE":
      return i18next.t("i18n:countrySE");
    case "IT":
      return i18next.t("i18n:countryIT");
    case "US":
      return i18next.t("i18n:countryUS");
    case "MU":
      return i18next.t("i18n:countryMU");
    default: {
      console.error("Unsupported country: " + country);
      return i18next.t("imagine:unknown");
    }
  }
};

const getLanguageText = (language: string) => {
  switch (language) {
    case "da":
      return i18next.t("i18n:danish");
    case "en-US":
      return i18next.t("i18n:englishUS");
    case "en-GB":
      return i18next.t("i18n:englishGB");
    default: {
      console.error("Unsupported language: " + language);
      return i18next.t("imagine:unknown");
    }
  }
};

const getYesNo = (value: boolean | number) => {
  if (value === 1 || value === true) return i18next.t("i18n:yes");
  else return i18next.t("i18n:no");
};

export const I18nText = {
  getCountryText,
  getLanguageText,
  getYesNo,
};
