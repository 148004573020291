import { I18nTools } from "imagine-i18n";
import { User, UserRole, UserText } from "..";
import { Option } from "imagine-essentials";
import i18next from "i18next";

const getDemoUser = () => {
  return {
    id: 0,
    email: "",
    role: UserRole.NONE,
    language: I18nTools.getValidLanguage(I18nTools.getBrowserLanguage()),
  } as User;
};

const getUserRoleOptions = (includeAll?: boolean) => {
  const options: Option[] = [];
  if (includeAll) {
    options.push({
      value: -1,
      label: i18next.t("imagine:all"),
    });
  }
  for (let i = 0; i < UserRole._SIZE; i++) {
    options.push({
      value: i,
      label: UserText.getUserRoleText(i),
    });
  }
  return options;
};

export const UserData = {
  getDemoUser,
  getUserRoleOptions,
};
