import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  ColorVariant,
  Device,
  DeviceSize,
  Link,
  NavbarItem,
  useWindowWidth,
} from "..";

interface Props {
  children?: React.ReactNode;
  color?: ColorVariant;
  items: NavbarItem[];
  collapseSize: DeviceSize;
}

export const NavigationHeader = (props: Props) => {
  const [navOpen, setNavOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const bodyRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();

  const navOpenClass = navOpen ? " open" : "";

  useEffect(() => {
    if (bodyRef.current) {
      const rect = bodyRef.current.getBoundingClientRect();
      setHeight(Math.max(bodyRef.current.scrollHeight, rect.height));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyRef.current, windowWidth]);

  const collapsibleStyle = useMemo(() => {
    if (Device.isMaxSize(windowWidth, props.collapseSize) && navOpen) {
      return {
        maxHeight: height + "px",
      };
    }
    return {};
  }, [height, navOpen, props.collapseSize, windowWidth]);

  const closeNav = () => {
    setNavOpen(false);
  };

  const collapseClass = useMemo(() => {
    if (props.collapseSize === DeviceSize.XS) {
      return " navbar-collapse--xs";
    }
    if (props.collapseSize === DeviceSize.SM) {
      return " navbar-collapse--sm";
    }
    if (props.collapseSize === DeviceSize.MD) {
      return " navbar-collapse--md";
    }
    if (props.collapseSize === DeviceSize.LG) {
      return " navbar-collapse--lg";
    }
    if (props.collapseSize === DeviceSize.XL) {
      return " navbar-collapse--xl";
    }
    return "";
  }, [props.collapseSize]);

  return (
    <div className={"navbar" + collapseClass}>
      <header className="navbar-header">
        <div className="navbar-brand">{props.children}</div>
        <div className="navbar-toggler">
          <Button
            onClick={() => setNavOpen(!navOpen)}
            icon="menu"
            transparent
          />
        </div>
      </header>

      <div className={"navbar-body" + navOpenClass} style={collapsibleStyle}>
        <div className="navbar-list-wrapper" ref={bodyRef}>
          <ul className="navbar-items">
            {props.items.map((item: NavbarItem, index: number) => (
              <li className="" key={index} onClick={closeNav}>
                {!item.children &&
                  (item.layout === "button" ? (
                    <Button
                      href={item.href}
                      to={item.to}
                      outline={item.outline}
                      transparent={item.transparent}
                      color={item.color}
                      target={item.target}
                      icon={item.icon}
                    >
                      {item.text}
                    </Button>
                  ) : (
                    <Link href={item.href} to={item.to} target={item.target}>
                      {item.text}
                    </Link>
                  ))}
                {/* If this is a dropdown */}
                {item.children &&
                  item.children.length > 0 &&
                  (item.layout === "button" ? (
                    <ButtonDropdown
                      text={item.text}
                      color={item.color}
                      outline={item.outline}
                      transparent={item.transparent}
                      icon={item.icon}
                      align="right"
                    >
                      {item.children.map(
                        (child: NavbarItem, subIndex: number) => (
                          <ButtonDropdownItem
                            text={child.text}
                            key={index + "-" + subIndex}
                            to={child.to}
                            href={child.href}
                          />
                        )
                      )}
                    </ButtonDropdown>
                  ) : (
                    <Link href={item.href} to={item.to}>
                      {item.text}
                    </Link>
                  ))}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
