interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const Label = (props: Props) => {
  return (
    <span className={"label" + (props.className ? " " + props.className : "")}>
      {props.children}
    </span>
  );
};
