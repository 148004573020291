import { useAppSelector } from "../store/store";
import { UserSelector } from "imagine-users";
import { Layout } from "../components";
import { KeyPage, PagesApi } from "imagine-pages";
import { useCallback, useEffect, useState } from "react";
import { Api, Tools } from "imagine-essentials";

export const LayoutContainer = () => {
  const user = useAppSelector(UserSelector.getUserNotNull);
  const [keyPages, setKeyPages] = useState<KeyPage[]>([]);

  const loadKeyPages = useCallback(async () => {
    const response = await PagesApi.getKeyPages();
    if (response.success && response.data) {
      setKeyPages(response.data);
    }
  }, []);

  useEffect(() => {
    loadKeyPages();
  }, [loadKeyPages]);

  const logVisit = useCallback(async () => {
    var source = document.referrer;

    const data = {};
    let path = "api/stats/log-visit";
    if (source) {
      path += "/" + Tools.sanitizeUrl(source);
    }

    const result = await Api.post(path, data);
    if (result.success) {
    } else {
      console.error("Failed to log activity");
    }
  }, []);

  const checkSource = useCallback(() => {
    const previousSource = localStorage.getItem("source");
    if (previousSource === null || previousSource === "") {
      var source = Tools.sanitizeUrl(document.referrer);
      localStorage.setItem("source", source);
    }
  }, []);

  useEffect(() => {
    checkSource();
    const timeout = setTimeout(() => {
      logVisit();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [checkSource, logVisit]);

  return <Layout user={user} keyPages={keyPages} />;
};
