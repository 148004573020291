import { createAsyncThunk } from "@reduxjs/toolkit";
import { UsersApi } from "../api";
import { UserRootState } from "..";

const fetchLoggedInUser = createAsyncThunk(
  "user/fetchLoggedInUser",
  async () => {
    return await UsersApi.getLoggedInUser();
  }
);

const postUsedFeatures = createAsyncThunk(
  "users/postUsedFeatured",
  async (_arg, thunkApi) => {
    const state: UserRootState = thunkApi.getState() as UserRootState;
    if (state.user.user === null) return [];
    if (state.user.user.id < 1) return [];
    const response = await UsersApi.postUsedFeatures(
      state.user.user.id,
      state.user.usedFeatures
    );
    if (!response.success) {
      return state.user.usedFeatures;
    }
    return [];
  }
);

export const UserThunks = {
  fetchLoggedInUser,
  postUsedFeatures,
};
