import { Button } from "imagine-ui";
import { ErrorCode } from "..";
import { useTranslation } from "react-i18next";
import { I18nTools, Language } from "imagine-i18n";

interface Props {
  error: ErrorCode;
  language: Language;
}

export const ErrorPage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-xl">
      {(props.error === ErrorCode.NOT_EXIST ||
        props.error === ErrorCode.NOT_PUBLISHED) && (
        <div className="">
          <h1 className="mb">{t("pages:oops")}</h1>
          <h3 className="mb">{t("pages:weCannotFindThisPage")}</h3>
          <Button
            className="mt"
            to={"/" + I18nTools.getLanguageUrlPrefix(props.language)}
          >
            {t("pages:goToFrontpageInstead")}
          </Button>
        </div>
      )}
    </div>
  );
};
