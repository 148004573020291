import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import webDa from "./lan/da_web.json";
import { usersDa } from "imagine-users";
import { membershipsDa } from "imagine-memberships";
import { imagineDa } from "imagine-essentials";
import { datetimeDa } from "imagine-datetime";
import { i18nDa } from "imagine-i18n";
import { pagesDa } from "imagine-pages";
import { projectDa } from "project";

import webEnGB from "./lan/en-GB_web.json" assert { type: "json" };
import { usersEnGB } from "imagine-users";
import { membershipsEnGB } from "imagine-memberships";
import { imagineEnGB } from "imagine-essentials";
import { datetimeEnGB } from "imagine-datetime";
import { i18nEnGB } from "imagine-i18n";
import { pagesEnGB } from "imagine-pages";
import { projectEnGB } from "project";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  da: {
    web: webDa,
    users: usersDa,
    memberships: membershipsDa,
    imagine: imagineDa,
    datetime: datetimeDa,
    i18n: i18nDa,
    project: projectDa,
    pages: pagesDa,
  },
  "en-GB": {
    web: webEnGB,
    users: usersEnGB,
    memberships: membershipsEnGB,
    imagine: imagineEnGB,
    datetime: datetimeEnGB,
    i18n: i18nEnGB,
    project: projectEnGB,
    pages: pagesEnGB,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    returnNull: false,
    ns: [
      "web",
      "users",
      "imagine",
      "memberships",
      "i18n",
      "datetime",
      "project",
    ],
    defaultNS: "web",
    resources,
    debug: true,
    lng: "da", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
