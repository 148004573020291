import { ApiResponse } from "../types/ApiResponse";
import { SentryReporter, TokenManager, Tokens } from "..";

const credentials = "include";

/**
 * Get the host of the API. If the environment is development, the host
 * is taken from the .env file. Otherwise, the host is the current
 * hostname.
 * @returns
 */
const getHost = () => {
  if (import.meta.env.DEV) {
    const protocol = window.location.protocol + "//";
    return protocol + process.env.DEV_HOST;
  } else {
    return window.location.origin;
  }
  // const protocol = window.location.protocol + "//";
  // return window.location.hostname === "localhost"
  //   ? defaultHost
  //   : protocol + window.location.hostname;
};

const getResponseData = async (response: Response) => {
  const headers = response.headers;
  try {
    if (headers.get("Content-Type") === "application/json") {
      const json = await response.json();
      return json;
    } else if (headers.get("Content-Type") === "application/pdf") {
      const blob = await response.blob();
      return blob;
    } else {
      const text = await response.text();
      return text;
    }
  } catch (error) {
    console.error("Unable to read data", error);
    console.error("Url:", response.url);
    console.error("Header content type:", headers.get("Content-Type"));
    console.error("Status:", response.status);
    SentryReporter.captureException(error, {
      Action: "Parse response data",
      Status: response.status,
    });
    return "";
  }
};

const getVersion = (response: Response) => {
  return response.headers.get("X-Api-Version");
};

const getRequestHeaders = () => {
  const accessToken = TokenManager.getToken("accessToken");
  if (accessToken === null || accessToken === undefined) {
    return undefined;
  } else
    return {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
};

const renewAccess = async () => {
  const refreshToken = TokenManager.getToken("refreshToken");
  if (refreshToken === null || refreshToken === undefined) {
    return false;
  }
  const data = {
    refreshToken: refreshToken,
  };
  const init: RequestInit = {
    method: "POST",
    mode: "cors",
    credentials: credentials,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  console.log("Requesting new tokens");
  try {
    const response: Response = await fetch(
      getHost() + "/api/users/refresh-access",
      init
    );
    const body = await getResponseData(response);

    if (response.status === 200) {
      console.log("Saving new tokens");
      TokenManager.saveTokens(body as Tokens);
      return true;
    } else {
      console.warn("Access token failed to renew");
      SentryReporter.captureException("Failed to renew access token", {
        "Status code": response.status,
        Body: body,
      });
      return false;
    }
  } catch (e) {
    console.error(e);
    SentryReporter.captureException(e, {
      Action: "Request new access token",
    });
    return false;
  }
};

/**
 * Updates the access- and refresh tokens stored in the browser. Use this when user's role, country or language has been updated.
 */
const updateLoggedInUser = async () => {
  return await renewAccess();
};

const requestGet = async (
  path: string,
  renew?: boolean | undefined
): Promise<Response | false> => {
  let init: RequestInit = {
    method: "GET",
    mode: "cors",
    credentials: credentials,
  };

  const headers = getRequestHeaders();
  if (headers !== undefined) {
    init = {
      ...init,
      ...headers,
    };
  }

  const response: Response = await fetch(getHost() + "/" + path, init);
  if (response.status === 412 && renew !== true) {
    console.log("Access token expired");
    const renewed = await renewAccess();
    if (renewed) {
      return requestGet(path, true);
    } else return false;
  }
  return response;
};

const get = async (path: string, retry = 1): Promise<ApiResponse> => {
  try {
    const response = await requestGet(path);
    if (response && response.ok === false) {
      console.error("Fetch API error:", JSON.stringify(response));
    }

    if (response === false) {
      // User needs to login
      TokenManager.clearTokens();
      return {
        success: false,
        status: 403,
        data: null,
        version: "",
      } as ApiResponse;
    }

    const body = await getResponseData(response);
    const version = getVersion(response);

    //const json = await response.json();
    return {
      success: response.status >= 200 && response.status < 300,
      status: response.status,
      data: body,
      version: version,
    } as ApiResponse;
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      return await get(path, retry - 1);
    }
    console.error(
      "Unable to get data from " + getHost() + "/" + path + ": ",
      error
    );
    SentryReporter.captureException(
      "Fetch API error",
      {
        Action: "Get data",
        Path: getHost() + "/" + path,
        Error: JSON.stringify(error),
      },
      undefined,
      "fatal"
    );

    return {
      success: false,
      status: 0,
      data: null,
      version: "",
    };
  }
};

const requestPost = async (
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  renew?: boolean | undefined
): Promise<Response | false> => {
  const init: RequestInit = {
    method: "POST",
    credentials: credentials,
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
  };

  const headers = getRequestHeaders();
  if (headers !== undefined) {
    init.headers = {
      ...init.headers,
      ...headers.headers,
    };
  }
  const response = await fetch(getHost() + "/" + path, init);
  if (response.status === 412 && renew !== true) {
    const renewed = await renewAccess();
    if (renewed) {
      return requestPost(path, data, true);
    } else return false;
  }
  return response;
};

const post = async (
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  retry = 1
): Promise<ApiResponse> => {
  try {
    const response = await requestPost(path, data);

    if (response && response.ok === false) {
      console.error("Fetch API error:", JSON.stringify(response));
    }

    if (response === false) {
      // User needs to login
      TokenManager.clearTokens();
      return {
        success: false,
        status: 403,
        data: null,
        version: "",
      } as ApiResponse;
    }

    const body = await getResponseData(response);
    const version = getVersion(response);
    if (response.status === 403) {
      console.warn("Permission denied to endpoint", path, body);
    }
    return {
      success: response.status >= 200 && response.status < 300,
      status: response.status,
      data: body,
      version: version,
    } as ApiResponse;
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return await post(path, data, retry - 1);
    }
    console.error(
      "Unable to post data to " + getHost() + "/" + path + ": ",
      error,
      data
    );
    SentryReporter.captureException(
      "Fetch API error",
      {
        Action: "Post data",
        Path: getHost() + "/" + path,
        Error: JSON.stringify(error),
      },
      undefined,
      "fatal"
    );
    return {
      success: false,
      status: 0,
      data: null,
      version: "",
    } as ApiResponse;
  }
};

const requestPostForm = async (
  path: string,
  formData: FormData,
  renew?: boolean | undefined
): Promise<Response | false> => {
  const init: RequestInit = {
    method: "POST",
    body: formData,
  };

  // const init: RequestInit = {
  //   method: "POST",
  //   credentials: credentials,
  //   mode: "cors",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "multipart/form-data",
  //   },
  //   body: formData,
  // };

  const headers = getRequestHeaders();
  if (headers !== undefined) {
    init.headers = {
      ...init.headers,
      ...headers.headers,
    };
  }

  const response = await fetch(getHost() + "/" + path, init);

  if (response.status === 412 && renew !== true) {
    const renewed = await renewAccess();
    if (renewed) {
      return requestPostForm(path, formData, true);
    } else return false;
  }
  return response;
};

const postForm = async (
  path: string,
  formData: FormData,
  retry = 1
): Promise<ApiResponse> => {
  try {
    const response = await requestPostForm(path, formData);

    if (response === false) {
      // User needs to login
      TokenManager.clearTokens();
      return {
        success: false,
        status: 403,
        data: null,
      } as ApiResponse;
    }

    const body = await getResponseData(response);
    const version = getVersion(response);
    if (response.status === 403) {
      console.warn("Permission denied to endpoint", path, body);
    }
    return {
      success: response.status >= 200 && response.status < 300,
      status: response.status,
      data: body,
      version: version,
    } as ApiResponse;
  } catch (error) {
    if (retry > 0) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      return await postForm(path, formData, retry - 1);
    }
    console.error("Unable to post form data to " + path + ": " + error);
    SentryReporter.captureException(
      error,
      { Action: "Post data", Path: getHost() + "/" + path },
      "fatal"
    );
    return {
      success: false,
      status: 0,
      data: null,
      version: "",
    } as ApiResponse;
  }
};

export const Api = {
  getHost,
  get,
  post,
  postForm,
  updateLoggedInUser,
};
