import i18next from "i18next";
import { CancelState, FeedbackType, UserRole } from "..";

const getCancelStateText = (cancelState: CancelState) => {
  switch (cancelState) {
    case CancelState.NOT_CANCELLED:
      return "";
    case CancelState.PAYMENT_NOT_COMPLETED:
      return i18next.t("users:paymentNotCompleted");
    case CancelState.REFUNDED:
      return i18next.t("users:refunded");
    case CancelState.WRONG_RENEWAL:
      return i18next.t("users:wrongRenewal");
  }
};

const getFeedbackTypeText = (type: FeedbackType) => {
  switch (type) {
    case FeedbackType.BUG_REPORT:
      return i18next.t("users:bugReport");
    case FeedbackType.FEATURE_REQUEST:
      return i18next.t("users:featureRequest");
    case FeedbackType.IMPROVEMENT:
      return i18next.t("users:improvement");
    case FeedbackType.QUESTION:
      return i18next.t("users:question");
    case FeedbackType.OTHER:
      return i18next.t("imagine:other");
  }
};

const getUserRoleText = (role: UserRole) => {
  switch (role) {
    case UserRole.NONE:
      return i18next.t("users:noRole");
    case UserRole.STANDARD:
      return i18next.t("users:standardUser");
    case UserRole.PREMIUM:
      return i18next.t("users:premiumUser");
    case UserRole.TRANSLATOR:
      return i18next.t("users:translator");
    case UserRole.MODERATOR:
      return i18next.t("users:moderator");
    case UserRole.ADMIN:
      return i18next.t("users:admin");
    case UserRole.PRO:
      return i18next.t("users:proUser");
    case UserRole.PARTNER:
      return i18next.t("users:partnerUser");
    default: {
      throw new Error("Unsupported role: " + role);
    }
  }
};

export const UserText = {
  getCancelStateText,
  getFeedbackTypeText,
  getUserRoleText,
};
