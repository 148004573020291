import { useEffect } from "react";
import { NavigationContent } from "../..";

type Props = {
  content: NavigationContent;
};

/**
 * Display a preview of the text content.
 */
export const NavigationContentView = (props: Props) => {
  useEffect(() => {}, []);

  return (
    <div className="content-area">
      <div className="row py-3">
        <div className="col-6 text-start">
          {props.content.previousText !== "" &&
            props.content.previousUrl !== "" && (
              <a href={props.content.previousUrl}>
                Forrige <br />
                <br />
                {props.content.previousText}
              </a>
            )}
        </div>
        <div className="col-6 text-end">
          {props.content.nextText !== "" && props.content.nextUrl !== "" && (
            <a href={props.content.nextUrl}>
              Næste <br />
              <br />
              {props.content.nextText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
