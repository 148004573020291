import * as Sentry from "@sentry/react";
import { SeverityLevel } from "@sentry/react";

/**
 * Capture an error in Sentry.
 * @param error An error string or object
 * @param data Additional data in object format
 * @param userData Additional user data
 * @param severity Severity level. Allowed values are 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
 */
const captureException = (
  error: any,
  data?: any,
  userData?: any,
  severity?: "fatal" | "error" | "warning" | "log" | "info" | "debug"
) => {
  const version = process.env.VERSION || "unknown";
  const name = process.env.NAME || "unknown";
  const scope = new Sentry.Scope();
  if (userData) {
    scope.setUser(userData);
  }
  scope.setContext("Data", data);
  if (severity) {
    scope.setLevel(severity as SeverityLevel);
  }
  scope.setContext(name, { Version: version });
  Sentry.captureException(error, scope);
  console.error(error, data);
};

export const SentryReporter = {
  captureException,
};
