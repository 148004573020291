import React from "react";
import { Link as ReactLink } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
  className?: string;
  target?: string;
  elementId?: string;
  onClick?: () => void; // A button that looks like a link, but has no automatic behavior
  href?: string; // Standard a element
  to?: string; // React router link
};

/**
 *
 * @param props
 */
export const Link = (props: Props) => {
  return (
    <>
      {props.href !== undefined && (
        <a
          className={props.className}
          href={props.href}
          id={props.elementId}
          target={props.target}
        >
          {props.children}
        </a>
      )}
      {props.to !== undefined && (
        <ReactLink
          to={props.to}
          id={props.elementId}
          className={props.className}
        >
          {props.children}
        </ReactLink>
      )}
      {props.onClick !== undefined && (
        <button
          className={"link " + (props.className || "")}
          id={props.elementId}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      )}
    </>
  );
};
