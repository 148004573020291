import { ThemeColor } from "..";
import { Option } from "imagine-essentials";

import { t } from "i18next";

const getThemeColorBackgroundClass = (color: ThemeColor) => {
  switch (color) {
    case ThemeColor.NONE:
      return "";
    case ThemeColor.DARK:
      return "bg-dark";
    case ThemeColor.LIGHT:
      return "bg-light";
    case ThemeColor.ACCENT:
      return "bg-accent";
    case ThemeColor.HIGHLIGHT:
      return "bg-secondary";
    case ThemeColor.WARNING:
      return "bg-warning";
    case ThemeColor.SECONDARY:
      return "bg-main";
    case ThemeColor.MAIN:
      return "bg-secondary";
    case ThemeColor.DANGER:
      return "bg-danger";
    default:
      console.error("Undefined theme color:", color);
      return "";
  }
};

const getThemeColorTextClass = (color: ThemeColor) => {
  switch (color) {
    case ThemeColor.NONE:
      return "";
    case ThemeColor.DARK:
      return "text-dark";
    case ThemeColor.LIGHT:
      return "text-light";
    case ThemeColor.ACCENT:
      return "text-accent";
    case ThemeColor.HIGHLIGHT:
      return "text-secondary";
    case ThemeColor.WARNING:
      return "text-warning";
    case ThemeColor.SECONDARY:
      return "text-main";
    case ThemeColor.MAIN:
      return "text-secondary";
    case ThemeColor.DANGER:
      return "text-danger";
    default:
      console.error("Undefined theme color:", color);
      return "";
  }
};

const getThemeColorButtonClass = (color: ThemeColor) => {
  switch (color) {
    case ThemeColor.NONE:
      return "";
    case ThemeColor.DARK:
      return "btn btn-dark";
    case ThemeColor.LIGHT:
      return "btn btn-light";
    case ThemeColor.ACCENT:
      return "btn btn-accent";
    case ThemeColor.HIGHLIGHT:
      return "btn btn-secondary";
    case ThemeColor.WARNING:
      return "btn btn-warning";
    case ThemeColor.SECONDARY:
      return "btn btn-secondary";
    case ThemeColor.MAIN:
      return "btn btn-main";
    case ThemeColor.DANGER:
      return "btn btn-danger";
    default:
      console.error("Undefined theme color:", color);
      return "";
  }
};

const getColorText = (color: ThemeColor) => {
  switch (color) {
    case ThemeColor.NONE:
      return t("imagine:none");
    case ThemeColor.DARK:
      return t("pagesAdmin:dark");
    case ThemeColor.LIGHT:
      return t("pagesAdmin:light");
    case ThemeColor.ACCENT:
      return t("pagesAdmin:accentColor");
    case ThemeColor.HIGHLIGHT:
      return t("pagesAdmin:highlightColor");
    case ThemeColor.WARNING:
      return t("pagesAdmin:warningColor");
    case ThemeColor.SECONDARY:
      return t("pagesAdmin:secondaryColor");
    case ThemeColor.MAIN:
      return t("pagesAdmin:mainColor");
    case ThemeColor.DANGER:
      return t("pagesAdmin:dangerColor");
    default:
      console.error("Undefined theme color:", color);
      return "";
  }
};

const getColorOptions = () => {
  const options: Option[] = [];

  for (let i = 0; i < ThemeColor._SIZE; i++) {
    options.push({
      value: i,
      label: getColorText(i),
    } as Option);
  }
  return options;
};

export const PageTheme = {
  getThemeColorBackgroundClass,
  getThemeColorTextClass,
  getThemeColorButtonClass,
  getColorText,
  getColorOptions,
};
