import { Alert, Button, Label, TextInput } from "imagine-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  errorMessage?: string;
  loading?: boolean;
  onRestorePassword: (password: string) => void;
  message?: string;
  loginUrl: string;
};

/**
 * The page displays the current user settings
 */
export const RestorePassword = (props: Props) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");

  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordRepeatedError, setNewPasswordRepeatedError] = useState("");

  const validate = () => {
    let valid = true;

    if (password.length < 6) {
      setNewPasswordError(t("users:passwordMin6Characters"));
      valid = false;
    } else setNewPasswordError("");

    if (password !== passwordRepeated) {
      setNewPasswordRepeatedError(t("users:repeatedPasswordNotMatching"));
      valid = false;
    } else setNewPasswordRepeatedError("");

    return valid;
  };

  const restorePassword = async () => {
    if (validate()) {
      props.onRestorePassword(password);
      setPassword("");
      setPasswordRepeated("");
    }
  };

  return (
    <>
      {props.message && (
        <div id="new-password-success">
          <p className="mb">{props.message}</p>
          <div className="text-center">
            <Button href={props.loginUrl} elementId="restore-password-login">
              {t("users:login")}
            </Button>
          </div>
        </div>
      )}
      {!props.message && (
        <div id="password-form">
          {props.errorMessage && (
            <Alert
              color="danger"
              elementId="general-new-password-error"
              className="mb"
            >
              {props.errorMessage}
            </Alert>
          )}

          <Label>{t("users:newPassword")}:</Label>
          <TextInput
            elementId="new-password"
            invalid={newPasswordError !== ""}
            value={password}
            onFinish={(value: string) => setPassword(value)}
            password={true}
          />
          <div className="text-danger" id="new-password-error">
            {newPasswordError}
          </div>

          <Label className="mt">{t("users:repeatNewPassword")}:</Label>
          <TextInput
            elementId="repeated-password"
            invalid={newPasswordRepeatedError !== ""}
            value={passwordRepeated}
            onFinish={(value: string) => setPasswordRepeated(value)}
            password={true}
          />
          <div className="text-danger" id="repeated-password-error">
            {newPasswordRepeatedError}
          </div>

          <div className="text-center pt">
            <Button
              elementId="save-password"
              onClick={restorePassword}
              loading={props.loading}
            >
              {t("imagine:save")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
