import { useEffect } from "react";
import { TextContent } from "../..";

type Props = {
  content: TextContent;
};

/**
 * Display a preview of the text content.
 */
export const TextContentView = (props: Props) => {
  useEffect(() => {}, []);

  return (
    <div className="text-content">
      <div dangerouslySetInnerHTML={{ __html: props.content.html }}></div>
    </div>
  );
};
