import { Icon, Link } from "..";

interface Props {
  text?: string;
  icon?: string;
  onClick?: () => void;
  href?: string;
  to?: string;
  disabled?: boolean;
  divider?: boolean; // Display a divider above the item
}

/**
 * Displays an item inside a dropdown menu.
 * @param props text (optional), onClick (optional), disabled (optional), divider (optional)
 * @returns
 */
export const ButtonDropdownItem = (props: Props) => {
  return (
    <>
      {props.divider && (
        <li>
          <hr className="separator" />
        </li>
      )}
      <li>
        {props.disabled && (
          <div className="item disabled">
            {props.icon && <Icon className="icon" name={props.icon} />}
            {props.text && <span className="text">{props.text}</span>}
          </div>
        )}
        {props.onClick && !props.disabled && (
          <button
            className="item"
            type="button"
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {props.icon && <Icon className="icon" name={props.icon} />}
            {props.text && <span className="text">{props.text}</span>}
          </button>
        )}
        {(props.href || props.to) && !props.disabled && (
          <Link className="item" href={props.href} to={props.to}>
            {props.icon && <Icon className="icon" name={props.icon} />}
            {props.text && <span className="text">{props.text}</span>}
          </Link>
        )}
      </li>
    </>
  );
};
