import { createSelector } from "@reduxjs/toolkit";
import { UserRole } from "..";
import { UserRootState } from "../types/UserRootState";
import { UserData } from "../utils/UserData";

export const UserSelector = {
  getUser: (state: UserRootState) => {
    return state.user.user;
  },
  getUserNotNull: createSelector(
    (state: UserRootState) => state.user.user,
    (user) => {
      if (user === null) {
        const demoUser = UserData.getDemoUser();
        return demoUser;
      }
      return user;
    }
  ),
  isLoggedIn: (state: UserRootState) => {
    if (state.user.user === null) return false;
    return state.user.user.id > 0;
  },
  getStatus: (state: UserRootState) => {
    return state.user.status;
  },
  getPreferences: (state: UserRootState) => {
    return state.user.preferences;
  },
  hasStandardAccess: (state: UserRootState) => {
    if (state.user.user === null) return false;
    const roles = [
      UserRole.STANDARD,
      UserRole.PREMIUM,
      UserRole.MODERATOR,
      UserRole.ADMIN,
    ];
    return roles.includes(state.user.user.role);
  },
  hasPremiumAccess: (state: UserRootState) => {
    if (state.user.user === null) return false;
    const roles = [UserRole.PREMIUM, UserRole.MODERATOR, UserRole.ADMIN];
    return roles.includes(state.user.user.role);
  },
  hasModeratorAccess: (state: UserRootState) => {
    if (state.user.user === null) return false;
    const roles = [UserRole.MODERATOR, UserRole.ADMIN];
    return roles.includes(state.user.user.role);
  },
  hasTranslatorAccess: (state: UserRootState) => {
    if (state.user.user === null) return false;
    const roles = [UserRole.TRANSLATOR, UserRole.MODERATOR, UserRole.ADMIN];
    return roles.includes(state.user.user.role);
  },
  hasAdminAccess: (state: UserRootState) => {
    if (state.user.user === null) return false;
    const roles = [UserRole.ADMIN, UserRole.MODERATOR, UserRole.TRANSLATOR];
    return roles.includes(state.user.user.role);
  },
  hasAdminSiteAccess: (state: UserRootState) => {
    if (state.user.user === null) return false;
    const roles = [UserRole.MODERATOR, UserRole.ADMIN, UserRole.TRANSLATOR];
    return roles.includes(state.user.user.role);
  },
  getUsedFeatures: (state: UserRootState) => {
    return state.user.usedFeatures;
  },
  getApiVersion: (state: UserRootState) => {
    return state.user.apiVersion;
  },
};
