import "imagine-icons/dist/imagine-icons.scss";

interface Props {
  name: string;
  className?: string;
}

export const Icon = (props: Props) => {
  // return <span className={"icon-" + props.name}>Icon</span>;
  return (
    <i
      className={
        "icon-" + props.name + " " + (props.className ? props.className : "")
      }
    />
  );
};
