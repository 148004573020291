import { useMemo, useState } from "react";
import { ColorVariant } from "../types";
import { Button } from "..";

interface Props {
  text?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  iconSize?: "xs" | "sm" | "md" | "lg" | "xl";
  children?: React.ReactNode;
  color?: ColorVariant;
  size?: "sm" | "md" | "lg";
  menuSize?: "sm" | "md" | "lg";
  align?: "left" | "right";
  transparent?: boolean;
  outline?: boolean;
  className?: string;
  elementId?: string;
}

/**
 * Displays a button. When clicked, a dropdown will open.
 * @param props
 * @returns
 */
export const ButtonDropdown = (props: Props) => {
  const [show, setShow] = useState(false);

  const style = useMemo(() => {
    if (props.align === "right") {
      return {
        right: 0,
      };
    } else {
      return {
        left: 0,
      };
    }
  }, [props.align]);

  const menuClass = "dropdown-menu-" + (props.menuSize || "md");

  return (
    <div
      className={
        "btn-dropdown" + (props.className ? " " + props.className : "")
      }
    >
      <Button
        size={props.size}
        color={props.color}
        transparent={props.transparent}
        outline={props.outline}
        onClick={() => setShow(!show)}
        icon={props.icon}
        iconPosition={props.iconPosition}
        iconSize={props.iconSize}
        elementId={props.elementId}
        active={show}
      >
        {props.text}
      </Button>
      {show && (
        <ul
          className={"btn-dropdown-menu " + menuClass}
          style={style}
          id={props.elementId + "-menu"}
          onClick={() => setShow(false)}
        >
          {props.children}
        </ul>
      )}
    </div>
  );
};
