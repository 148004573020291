import { SnippetBaseProps, SnippetContent } from "../..";

export interface SnippetContentViewProps {
  content: SnippetContent;
  props: SnippetBaseProps;
}

/**
 * Display a preview of the snippet.
 */
// export const SnippetContentView = (props: Props) => {
//   return <div>{<h1>&#60;{props.content.name}&#62;</h1>}</div>;
// };

export const withSnippetContentView = (
  Component: React.ComponentType<SnippetContentViewProps>
) => {
  return (props: SnippetContentViewProps) => {
    // You can add additional logic here if needed
    return <Component {...props} />;
  };
};
