import { createAsyncThunk } from "@reduxjs/toolkit";
import { MembershipsApi, SubscriptionDataApi } from "../api";

const fetchMembershipData = createAsyncThunk(
  "membership/fetchMembershipData",
  async () => {
    return await MembershipsApi.getUserMembership();
  }
);

const fetchUserSubscriptions = createAsyncThunk(
  "membership/fetchUserSubscriptions",
  async () => {
    return await SubscriptionDataApi.getUserSubscriptionData();
  }
);

export const MembershipThunks = {
  fetchMembershipData,
  fetchUserSubscriptions,
};
