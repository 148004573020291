import { Api, DataResponse, SentryReporter } from "imagine-essentials";
import { KeyPage, Page, PageConverter } from "..";

const getPageContent = async (language: string, url: string) => {
  let path = language;
  if (url !== "") path += "/" + url;
  const result = await Api.get("api/page/" + path);
  const response: DataResponse<Page> = {
    success: result.success,
    data: undefined,
  };

  if (result.success) {
    try {
      response.data = result.data as Page;
    } catch (e) {
      SentryReporter.captureException("Failed to parse page content", {
        Path: path,
        Result: JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get page content", {
      Path: path,
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getPages = async () => {
  const result = await Api.post("api/pages/filter", {});
  const response: DataResponse<Page[]> = {
    success: result.success,
  };

  if (result.success && result.data) {
    try {
      response.data = result.data as Page[];
    } catch (e) {
      SentryReporter.captureException("Failed to parse pages", {
        Result: JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get pages", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getKeyPages = async () => {
  const result = await Api.get("api/pages/key-pages");
  const response: DataResponse<KeyPage[]> = {
    success: result.success,
  };

  if (result.success && result.data) {
    try {
      response.data = PageConverter.getKeyPages(result.data as Page[]);
    } catch (e) {
      SentryReporter.captureException("Failed to parse key pages", {
        Result: JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get key pages", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getPage = async (pageId: number) => {
  const result = await Api.get("api/pages/id/" + pageId);
  const response: DataResponse<Page> = {
    success: result.success,
  };

  if (result.success && result.data) {
    try {
      response.data = {
        ...result.data,
        content: JSON.parse(result.data.content),
      };
      // response.data = result.data as Page;
    } catch (e) {
      SentryReporter.captureException("Failed to parse page", {
        Result: JSON.stringify(result),
      });
    }
  } else {
    SentryReporter.captureException("Failed to get page", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

export const PagesApi = {
  getPageContent,
  getPages,
  getKeyPages,
  getPage,
};
