import { useEffect } from "react";
import { ImageContent, ImageContentStyle } from "../..";
import { Api } from "imagine-essentials";

type Props = {
  content: ImageContent;
};

/**
 * Display a preview of the text content.
 */
export const ImageContentView = (props: Props) => {
  useEffect(() => {}, []);

  const getContainerClass = () => {
    let className = "image-content ";
    if (props.content.style === ImageContentStyle.TEXT_WIDTH)
      className += "text-width";
    else if (props.content.style === ImageContentStyle.HALF_CONTENT_WIDTH)
      className += "half-content-width";
    else if (props.content.style === ImageContentStyle.WINDOW_WIDTH) {
      className += "window-width";
    } else if (props.content.style === ImageContentStyle.CONTENT_WIDTH) {
      className += "content-width";
    } else if (props.content.style === ImageContentStyle.ORIGINAL_WIDTH) {
      className += "original-width";
    }
    return className;
  };

  return (
    <div className={getContainerClass()}>
      <img
        className={
          props.content.style !== ImageContentStyle.ORIGINAL_WIDTH
            ? "fluid"
            : ""
        }
        src={Api.getHost() + props.content.filePath}
        alt={props.content.altText}
      />
    </div>
  );
};
