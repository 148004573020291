import {
  MembershipPeriod,
  MembershipProductTemplate,
  MembershipType,
} from "imagine-memberships";

export const MembershipProductTemplates: MembershipProductTemplate[] = [
  {
    type: MembershipType.FREE,
    countries: [
      {
        country: "DK",
        currency: "DKK",
        price: 0,
      },
      {
        country: "GB",
        currency: "GBP",
        price: 0,
      },
      {
        country: "IE",
        currency: "EUR",
        price: 0,
      },
    ],
    titleKey: "project:basisMembership",
    period: MembershipPeriod.NONE,
    featureKeys: [
      "project:insertPlantsAndObjects",
      "project:overviewSeasonColors",
      "project:createPlantsAndObjects",
      "project:generatePlanList",
    ],
  },
  {
    type: MembershipType.PREMIUM_1_MONTH,
    countries: [
      {
        country: "DK",
        currency: "DKK",
        price: 49,
      },
      {
        country: "GB",
        currency: "GBP",
        price: 5.79,
      },
      {
        country: "IE",
        currency: "EUR",
        price: 6.79,
      },
    ],
    titleKey: "project:premiumMembershipMonth",
    period: MembershipPeriod.MONTH,
    featureKeys: [
      "project:insertPlantsAndObjects",
      "project:overviewSeasonColors",
      "project:createPlantsAndObjects",
      "project:generatePlanList",
      "project:createPlantCollections",
      "project:saveUpTo10Plans",
      "project:drawPropertyOnSatelliteMap",
    ],
  },
  {
    type: MembershipType.PREMIUM_1_YEAR,
    countries: [
      {
        country: "DK",
        currency: "DKK",
        price: 299,
      },
      {
        country: "GB",
        currency: "GBP",
        price: 34.9,
      },
      {
        country: "IE",
        currency: "EUR",
        price: 39.9,
      },
    ],
    titleKey: "project:premiumMembershipYear",
    period: MembershipPeriod.YEAR,
    featureKeys: [
      "project:insertPlantsAndObjects",
      "project:overviewSeasonColors",
      "project:createPlantsAndObjects",
      "project:generatePlanList",
      "project:createPlantCollections",
      "project:saveUpTo10Plans",
      "project:drawPropertyOnSatelliteMap",
    ],
  },
  {
    type: MembershipType.PREMIUM_FREE,
    countries: [
      {
        country: "DK",
        currency: "DKK",
        price: 0,
      },
      {
        country: "GB",
        currency: "GBP",
        price: 0,
      },
      {
        country: "IE",
        currency: "EUR",
        price: 0,
      },
    ],
    titleKey: "project:premiumMembershipFree",
    period: MembershipPeriod.UNLIMITED,
    featureKeys: [
      "project:insertPlantsAndObjects",
      "project:overviewSeasonColors",
      "project:createPlantsAndObjects",
      "project:generatePlanList",
      "project:saveUpTo10Plans",
      "project:drawPropertyOnSatelliteMap",
    ],
  },
];

// const membershipProductsOld: MembershipProduct[] = [
//   {
//     visible: true,
//     createAccountButton: [UserRole.NONE],
//     type: MembershipType.FREE,
//     price: 0,
//     currency: "DKK",
//     titleKey: "basisMembership",
//     period: MembershipPeriod.NONE,
//     featureKeys: [
//       "insertPlantsAndObjects",
//       "overviewSeasonColors",
//       "createPlantsAndObjects",
//       "generatePlanList",
//     ],
//   },
//   {
//     visible: true,
//     buyButton: [UserRole.NONE, UserRole.STANDARD],
//     changeMembershipButton: [UserRole.PREMIUM],
//     type: MembershipType.PREMIUM_1_MONTH,
//     price: 49,
//     currency: "DKK",
//     titleKey: "premiumMembershipMonth",
//     period: MembershipPeriod.MONTH,
//     featureKeys: [
//       "insertPlantsAndObjects",
//       "overviewSeasonColors",
//       "createPlantsAndObjects",
//       "generatePlanList",
//       "createPlantCollections",
//       "saveUpTo10Plans",
//       "drawPropertyOnSatelliteMap",
//     ],
//   },
//   {
//     visible: true,
//     buyButton: [UserRole.NONE, UserRole.STANDARD],
//     changeMembershipButton: [UserRole.PREMIUM],
//     type: MembershipType.PREMIUM_1_YEAR,
//     price: 299,
//     currency: "DKK",
//     titleKey: "premiumMembershipYear",
//     period: MembershipPeriod.YEAR,
//     featureKeys: [
//       "insertPlantsAndObjects",
//       "overviewSeasonColors",
//       "createPlantsAndObjects",
//       "generatePlanList",
//       "createPlantCollections",
//       "saveUpTo10Plans",
//       "drawPropertyOnSatelliteMap",
//     ],
//   },
//   {
//     visible: false,
//     type: MembershipType.PREMIUM_FREE,
//     price: 0,
//     currency: "DKK",
//     titleKey: "premiumMembershipFree",
//     period: MembershipPeriod.UNLIMITED,
//     featureKeys: [
//       "insertPlantsAndObjects",
//       "overviewSeasonColors",
//       "createPlantsAndObjects",
//       "generatePlanList",
//       "saveUpTo10Plans",
//       "drawPropertyOnSatelliteMap",
//     ],
//   },
// ];

// const getMembershipProducts = (country: string) => {
//   switch (country) {
//     case "DK":
//       return membershipProducts;
//     case "GB":
//       return [
//         {
//           ...membershipProducts[0],
//           currency: "GBP",
//         },
//         {
//           ...membershipProducts[1],
//           price: 6,
//           currency: "GBP",
//         },
//         {
//           ...membershipProducts[2],
//           price: 69,
//           currency: "GBP",
//         },
//       ];
//     default:
//       return [];
//   }
// };

// const getMembershipOverview = (country: string) => {
//   switch (country) {
//     case "DK":
//       return [
//         membershipProducts[0],
//         membershipProducts[1],
//         membershipProducts[2],
//       ];
//     case "GB":
//       return [
//         {
//           ...membershipProducts[0],
//           price: 6,
//           currency: "GBP",
//         },
//         {
//           ...membershipProducts[1],
//           price: 69,
//           currency: "GBP",
//         },
//       ];
//     default:
//       return [];
//   }
// };

// export const MembershipProducts = {

//   // getMembershipProducts,
//   // getMembershipOverview,
// };
