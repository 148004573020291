import { useEffect } from "react";
import { VideoContent } from "../..";
import { Api } from "imagine-essentials";

type Props = {
  content: VideoContent;
};

/**
 * Display a preview of the text content.
 */
export const VideoContentView = (props: Props) => {
  useEffect(() => {}, []);

  return (
    <div className="video-content">
      <video controls>
        <source src={Api.getHost() + props.content.filePath} type="video/mp4" />
        {props.content.altText}
      </video>
    </div>
  );
};
