/**
 * Each project must have this file in the root of the project. The values might be changed.
 */
const ImagineConfig = {
  // These breakpoints should match the breakpoints given in the imagine-style package
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  },
  supportedLanguages: ["da", "en-GB"],
  defaultLanguage: "en-GB",
  supportedCountries: ["DK", "GB"], //, "IE"],
  languageUrlMap: [
    {
      language: "en-GB",
      urlPrefix: "",
    },
    {
      language: "da",
      urlPrefix: "da",
    },
  ],
  stripePublicKeyLive:
    "pk_live_51PQ5WBAdBYMmnHSMZ1OobwgWoc7T0lC3t8Xww7NnMglJdwb3DYlgruRHzfxMtTwaM151oxmi0G2feXLmz24UfC9X00MpcumLFq",
  stripePublicKeyTest:
    "pk_test_51PQ5WBAdBYMmnHSMKFW6B1YQzXNbwhCS9IiYnUFUqs8aGH5Ky5kEIksSLEMJ44JaSXUcIE7ocvzHpHimiV0JBq3700viqicXDX",
  colors: {
    accent: "#479966",
    secondary: "#f5f3f2",
    secondaryDark: "#dfd8d5",
  },
};

export default ImagineConfig;
