import { useMemo } from "react";
import { ColorVariant } from "../types";
import { Icon } from "..";
import { Link as ReactLink } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  color?: ColorVariant;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  active?: boolean;
  outline?: boolean;
  transparent?: boolean; // Opposite of outline (cannot use both)
  onClick?: () => void;
  href?: string; // Set this if button should be rendered as a link (but still look like a button)
  className?: string;
  elementId?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  iconSize?: "xs" | "sm" | "md" | "lg" | "xl";
  loading?: boolean;
  spinnerColor?: ColorVariant;
  propagateEvent?: boolean;
  to?: string; // React router link
  target?: string;
}

export const Button = (props: Props) => {
  const className = useMemo(() => {
    let className = "btn";
    if (props.size !== undefined && props.size !== "md") {
      className += " btn-" + props.size;
    }
    if (props.color) {
      className += " btn-" + props.color;
    } else {
      className += " btn-accent";
    }
    if (props.outline) {
      className += "-outline";
    } else if (props.transparent) {
      className += "-transparent";
    }

    if (props.disabled) {
      className += " disabled";
    }

    if (props.active) {
      className += " active";
    }

    if (props.className) {
      className += " " + props.className;
    }

    if (props.icon && !props.children) {
      className += " square";
    }
    return className;
  }, [
    props.active,
    props.children,
    props.className,
    props.color,
    props.disabled,
    props.icon,
    props.outline,
    props.size,
    props.transparent,
  ]);

  const spinnerClass = useMemo(() => {
    if (!props.loading) return "";
    let className = "spinner";
    if (props.children) {
      className += " mr-sm";
    }

    className += " spinner-sm";
    return className;
  }, [props.children, props.loading]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const click = (event: any) => {
    if (!props.propagateEvent) {
      event.stopPropagation();
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  const iconSizeClass = props.iconSize ? " icon-" + props.iconSize : "";

  return (
    <>
      {props.href && (
        <a
          href={props.disabled ? undefined : props.href}
          className={className}
          target={props.target}
          id={props.elementId}
        >
          <div className="row items-center items-y-center">
            {props.icon && props.iconPosition !== "right" && !props.loading && (
              <Icon
                name={props.icon}
                className={
                  (props.children ? " icon mr-sm" : " icon") + iconSizeClass
                }
              />
            )}
            {props.loading && (
              <div>
                <span className={spinnerClass}></span>
              </div>
            )}

            {props.children}
            {props.icon && props.iconPosition === "right" && !props.loading && (
              <Icon
                name={props.icon}
                className={
                  (props.children ? " icon ml-sm" : " icon") + iconSizeClass
                }
              />
            )}
          </div>
        </a>
      )}
      {props.to && (
        <ReactLink to={props.to} id={props.elementId} className={className}>
          {props.children}
        </ReactLink>
      )}
      {!props.href && !props.to && (
        <button
          className={className}
          type="button"
          disabled={props.disabled}
          onClick={click}
          id={props.elementId}
        >
          <div className="row items-center items-y-center">
            {props.icon && props.iconPosition !== "right" && !props.loading && (
              <Icon
                name={props.icon}
                className={
                  (props.children ? " icon mr-sm" : " icon") + iconSizeClass
                }
              />
            )}
            {props.loading && (
              <div>
                <span className={spinnerClass}></span>
              </div>
            )}

            {props.children}
            {props.icon && props.iconPosition === "right" && !props.loading && (
              <Icon
                name={props.icon}
                className={
                  (props.children ? " icon ml-sm" : " icon") + iconSizeClass
                }
              />
            )}
          </div>
        </button>
      )}
    </>
  );
};

export default Button;
