import i18next from "i18next";
import { CancelReason, CancelState, MembershipPeriod } from "..";

const getCancelStateText = (cancelState: CancelState) => {
  switch (cancelState) {
    case CancelState.NOT_CANCELLED:
      return "";
    case CancelState.PAYMENT_NOT_COMPLETED:
      return i18next.t("memberships:paymentNotCompleted");
    case CancelState.REFUNDED:
      return i18next.t("memberships:refunded");
    case CancelState.WRONG_RENEWAL:
      return i18next.t("memberships:wrongRenewal");
  }
};

const getMembershipPeriodText = (period: MembershipPeriod) => {
  switch (period) {
    case MembershipPeriod.SINGLE_PAYMENT:
      return i18next.t("memberships:singlePayment");
    case MembershipPeriod.MONTH:
      return i18next.t("memberships:perMonth");
    case MembershipPeriod.YEAR:
      return i18next.t("memberships:perYear");
    case MembershipPeriod.NONE:
      return "";
    default:
      return "";
  }
};

const getProductPeriodText = (period: string) => {
  switch (period) {
    case "once":
      return "";
    // return i18next.t("memberships:singlePayment");
    case "month":
      return i18next.t("memberships:perMonth");
    case "year":
      return i18next.t("memberships:perYear");
    default:
      return "";
  }
};

const getProductIntervalTitleText = (interval: string) => {
  switch (interval) {
    case "month":
      return i18next.t("memberships:month").toLowerCase();
    case "year":
      return i18next.t("memberships:year").toLowerCase();
    default:
      return "";
  }
};

const getCancelReasonText = (reason: CancelReason) => {
  switch (reason) {
    case CancelReason.FINISHED_WORK:
      return i18next.t("finishedWork");
    case CancelReason.TOO_MANY_BUGS:
      return i18next.t("tooManyBugs");
    case CancelReason.TOO_DIFFICULT_TO_USER:
      return i18next.t("tooDifficultToUse");
    case CancelReason.MISSING_FEATURES:
      return i18next.t("missingFeatures");
    case CancelReason.TOO_EXPENSIVE:
      return i18next.t("tooExpensive");
    default:
      throw new Error("Cancel reason not supported: " + reason);
  }
};

export const MembershipsText = {
  getCancelStateText,
  getMembershipPeriodText,
  getProductPeriodText,
  getProductIntervalTitleText,
  getCancelReasonText,
};
