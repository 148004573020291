import { t } from "i18next";
import { Api, AppInfo, DataResponse, SentryReporter } from "imagine-essentials";
import {
  AvailableMembershipDeals,
  CancelReason,
  CancelState,
  Customer,
  FreepaySubscriptionSchema,
  Membership,
  MembershipType,
} from "..";
import { Currency } from "imagine-i18n";
import { DateFilter } from "imagine-datetime";

const getUserMembership = async () => {
  const result = await Api.get("api/users/details");
  if (result.success) {
    return result.data;
  } else {
    SentryReporter.captureException("Failed to get membership data", {
      "Server response": JSON.stringify(result),
    });
    return {
      errorMessage: t("memberships:errorGettingData"),
    };
  }
};

/**
 * The current membership will not be renewed when it expires.
 * @param userId
 * @param reasons
 * @param comment
 * @returns
 */
const cancelSubscription = async (
  userId: number,
  reasons: CancelReason[],
  comment: string
) => {
  const result = await Api.post(
    "api/memberships/" + userId + "/deactivate-renew",
    {
      reason: reasons,
      comment: comment,
      version: AppInfo.getAppVersion(),
    }
  );
  if (result.success) {
    let msg = t("memberships:subscriptionCancelled");
    if (reasons.length > 0 || comment !== "") {
      msg = t("memberships:thanksForCancelFeedback") + " " + msg;
    }
    return {
      successMessage: msg,
    };
  } else {
    SentryReporter.captureException("Failed to unsubscripe", {
      "Server response": JSON.stringify(result),
    });
    return {
      errorMessage: t("memberships:cancelSubscriptionFailed"),
    };
  }
};

const refund = async (membershipId: number, amount: number, note: string) => {
  const data = {
    amount: amount,
    note: note,
    membershipId: membershipId,
  };
  const result = await Api.post(
    "api/memberships/" + membershipId + "/refund",
    data
  );
  if (result.success) {
    return {
      success: true,
    };
  } else {
    SentryReporter.captureException("Failed to refund", {
      "Server response": JSON.stringify(result),
    });
    return {
      errorMessage: t("users:refundFailed"),
    };
  }
};

/**
 * Membership is immediately closes and payment is refunded
 * @param membershipId
 * @param cancelState
 * @returns
 */
const cancelMembership = async (
  membershipId: number,
  cancelState: CancelState
) => {
  const result = await Api.post("api/memberships/" + membershipId + "/cancel", {
    reason: cancelState,
  });
  if (result.success) {
    return {
      success: true,
    };
  } else {
    SentryReporter.captureException("Failed to refund", {
      "Server response": JSON.stringify(result),
    });
    return {
      errorMessage: t("users:cancelMembershipFailed"),
    };
  }
};

/**
 * Check if local storage contains a special deal token
 */
const getMembershipDeals = async () => {
  const item = localStorage.getItem("dealToken");
  const response: DataResponse<AvailableMembershipDeals> = {
    success: false,
  };

  if (item !== null) {
    try {
      const itemObj = JSON.parse(item);
      if (itemObj.token !== undefined && itemObj.expiry !== undefined) {
        // Check expiry
        if (itemObj.expiry > Date.now()) {
          const result = await Api.get("api/memberships/deal/" + itemObj.token);
          if (result.success) {
            response.success = true;
            if (result.data) {
              response.data = { deal: result.data };
              // Check if there is a continuation deal
              if (result.data.continuationDealToken !== "") {
                const result2 = await Api.get(
                  "api/memberships/deal/" + result.data.continuationDealToken
                );
                if (result2.success && result2.data) {
                  response.data.continuationDeal = result2.data;
                }
              }
            }
          }
        }
      }
    } catch (e) {
      SentryReporter.captureException(e, {
        Action: "Parsing membership deal",
      });
    }
  }
  return response;
};
/**
 * Validates the customer info and opens the payment window.
 */
const preparePayment = async (
  membershipType: MembershipType,
  customerInfo: Customer,
  userId: number,
  currency: Currency,
  membershipDealToken?: string
) => {
  // Make sure the user access token is stored in the local storage (even if the user did not
  // select "Remember me"). Otherwise user will not be logged in after returning to the app from
  // the payment window.

  // Api.forceRememberMe();

  const data = {
    type: membershipType,
    currency: currency,
    userId: userId,
    fullName: customerInfo.name,
    address1: customerInfo.address1,
    address2: customerInfo.address2,
    postCode: customerInfo.postCode,
    city: customerInfo.city,
    country: customerInfo.country,
    membershipDeal: membershipDealToken ?? "",
  };

  const result = await Api.post("api/memberships/prepare-order", data);

  if (result.success) {
    if (result.data.paymentWindowLink !== undefined) {
      return {
        paymentWindowLink: result.data.paymentWindowLink,
      };
      // setPaymentLink(result.data.paymentWindowLink);
      // setStep(CheckoutStep.PAYMENT);
      // sessionStorage.setItem("checkoutStep", CheckoutStep.PAYMENT.toString());
    }
  }
  SentryReporter.captureException("Error preparing membership payment", {
    "Server response": JSON.stringify(result),
  });
  return {
    errorMessage: t("users:errorPreparingMembershipOrder"),
  };
};

/**
 * Find a recently purchased membership based on the payment identifier (and the user token).
 * @param paymentIdentifier
 * @returns Object containing membership and possible deal. errorMessage on error.
 */
const findPurchasedMembership = async (paymentIdentifier: string) => {
  const result = await Api.get("api/memberships/find/" + paymentIdentifier);
  if (result.success) {
    if (result.data.membership) {
      return result.data.membership as Membership;
      // Contain
      // return {
      //   membership: result.data.membership,
      //   deal: result.data.deal || undefined,
      // };
    }
  }

  SentryReporter.captureException("No membership data in response", {
    Action: "Finding purchased membership data",
    "Payment identifier": paymentIdentifier,
    "Server response": JSON.stringify(result),
  });
  return undefined;
};

const getMemberships = async (filter: DateFilter) => {
  const result = await Api.post("api/memberships/filter", filter);
  const response: DataResponse<Membership[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data as Membership[];
  } else {
    SentryReporter.captureException("Failed to get memberships", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getSubscriptionInfo = async (paymendId: number) => {
  const result = await Api.get(
    "api/memberships/subscription/" + paymendId + "/raw"
  );
  const response: DataResponse<{
    paymentInfo: FreepaySubscriptionSchema;
    subscriptionInfo: FreepaySubscriptionSchema;
  }> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = {
      paymentInfo: result.data.paymentInfo.data,
      subscriptionInfo: result.data.subscriptionInfo.data,
    };
  } else {
    SentryReporter.captureException("Failed to get subscription info", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const excludeMembership = async (membershipId: number) => {
  const result = await Api.post(
    "api/memberships/" + membershipId + "/exclude",
    {}
  );
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to exclude membership", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const includeMembership = async (membershipId: number) => {
  const result = await Api.post(
    "api/memberships/" + membershipId + "/include",
    {}
  );
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to include membership", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

export const MembershipsApi = {
  getUserMembership,
  cancelSubscription,
  refund,
  cancelMembership,
  getMembershipDeals,
  preparePayment,
  findPurchasedMembership,
  getMemberships,
  getSubscriptionInfo,
  excludeMembership,
  includeMembership,
};
