/**
 * Reason for cancelling a membership. This action can only be done by an admin.
 */
export enum CancelState {
  NOT_CANCELLED = 0,
  PAYMENT_NOT_COMPLETED = 1, // Used when the membership was cancelled by admin due to failed payment
  REFUNDED = 2, // Used when the user asked to cancel membership and get payment refunded (hopefully rare)
  WRONG_RENEWAL = 3, // Used when a membership was renewed, but should not have been
  _SIZE = 4,
}
