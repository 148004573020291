import { CurrencyTools, CurrencyPlacement, Currency } from "imagine-i18n";
import { Button } from "..";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  price: number;
  currency: Currency;
  discount?: number; // Discount factor (0.5 = 50% discount)
  discountName?: string;
  discountDescription?: string;
  period: string;
  subPeriod?: string; // A smaller period text display below period
  features: string[];
  className?: string;
  buttonText?: string;
  footerText?: string;
  onButtonClick?: () => void;
  elementId?: string;
  actualPrice?: boolean; // If the provided price is the actual price where the discount is already applied
  hideButton?: boolean;
}

export const ProductCard = (props: Props) => {
  const { t } = useTranslation();

  const renderPriceElement = () => {
    if (props.price === undefined || props.currency === undefined) return <></>;
    let displayPrice = CurrencyTools.getDisplayPrice(
      props.price,
      props.currency,
      false,
      CurrencyPlacement.NO_CURRENCY
    );
    if (props.discount && !props.actualPrice) {
      displayPrice = CurrencyTools.getDisplayPrice(
        props.price - props.price * props.discount,
        props.currency,
        false,
        CurrencyPlacement.NO_CURRENCY
      );
    }
    const placement = CurrencyTools.getDefaultCurrencyPlacement(props.currency);
    return (
      <div className="price">
        {placement === CurrencyPlacement.BEFORE && (
          <span
            className="currency"
            id={
              (props.elementId ? props.elementId + "-" : "") + "price-currency"
            }
          >
            {CurrencyTools.getCurrencyDisplayUnit(props.currency)}
          </span>
        )}
        <span
          className="value"
          id={(props.elementId ? props.elementId + "-" : "") + "price-amount"}
        >
          {displayPrice}
        </span>
        {placement === CurrencyPlacement.AFTER && (
          <span
            className="currency"
            id={
              (props.elementId ? props.elementId + "-" : "") + "price-currency"
            }
          >
            {CurrencyTools.getCurrencyDisplayUnit(props.currency)}
          </span>
        )}
      </div>
    );
  };

  const renderDiscountName = () => {
    const name = props.discountName ?? "";
    return (
      <div
        className={"discount-name" + (props.discount ? " active" : "")}
        id={(props.elementId ? props.elementId + "-" : "") + "deal-name"}
      >
        &nbsp;{name}&nbsp;
      </div>
    );
  };

  const renderOriginalPriceElement = () => {
    if (props.price === undefined || props.currency === undefined) return <></>;
    let originalPrice = "";
    let discount = "";
    if (props.discount) {
      if (props.actualPrice) {
        originalPrice = CurrencyTools.getDisplayPrice(
          props.price / (1 - props.discount),
          props.currency,
          false
        );
      } else {
        originalPrice = CurrencyTools.getDisplayPrice(
          props.price,
          props.currency,
          false
        );
      }
      discount =
        t("imagine:discountSave") +
        " " +
        Math.round(props.discount * 100) +
        "%";
    }

    return (
      <div className="original-price">
        <span className="deprecated-price">{originalPrice}</span>
        <span className="discount">{discount}</span>
      </div>
    );
  };

  const renderPeriodElement = () => {
    // The nbsp will make sure the line height is kept even when content is empty
    return (
      <div className="period">
        &nbsp;{props.period}&nbsp;
        {props.subPeriod && (
          <div className="sub-period">&nbsp;{props.subPeriod}&nbsp;</div>
        )}
      </div>
    );
  };

  return (
    <div className={"product-card " + (props.className ? props.className : "")}>
      <div
        className="title"
        id={(props.elementId ? props.elementId + "-" : "") + "title"}
      >
        {props.title}
      </div>
      {renderDiscountName()}
      <div className="body">
        {renderOriginalPriceElement()}
        {renderPriceElement()}
        {renderPeriodElement()}

        <ul className="features">
          {props.features.map((feature: string, index: number) => (
            <li className="yes" key={index}>
              {feature}
            </li>
          ))}
        </ul>

        {props.discountDescription && (
          <div className="discount-description">
            {props.discountDescription}
          </div>
        )}
        <div className="stretch"></div>

        {props.onButtonClick && props.buttonText && !props.hideButton && (
          <>
            <div className="row items-stretch">
              <Button
                className="px"
                elementId={props.elementId + "-buy-button"}
                onClick={props.onButtonClick}
                size="lg"
              >
                {props.buttonText}
              </Button>
            </div>
          </>
        )}
        {props.footerText && (
          <p className="mt px text-italic text-center text-sm">
            {props.footerText}
          </p>
        )}
      </div>
    </div>
  );
};
