import { useMemo } from "react";
import { ColorVariant } from "..";

interface Props {
  size?: "sm" | "md" | "lg";
  color?: ColorVariant;
  className?: string;
}

export const Spinner = (props: Props) => {
  const className = useMemo(() => {
    let className = "spinner";
    if (props.size) {
      className += " spinner-" + props.size;
    } else {
      className += " spinner-md";
    }
    if (props.color) {
      className += " spinner-" + props.color;
    } else {
      className += " spinner-dark";
    }
    if (props.className) className += " " + props.className;
    return className;
  }, [props.className, props.color, props.size]);
  return <span className={className}></span>;
};
