import { Country } from "imagine-i18n";
import {
  Customer,
  Membership,
  MembershipProduct,
  MembershipProductCountry,
  MembershipProductTemplate,
  MembershipType,
} from "..";
import { UserRole } from "imagine-users";
import { DateTimeTools } from "imagine-datetime";

/**
 * Get all membership products available in a given country (also includes free and non buyable)
 * @param membershipProductTemplates
 * @param country
 */
const getMembershipProducts = (
  membershipProductTemplates: MembershipProductTemplate[],
  country: Country
) => {
  const membershipProducts: MembershipProduct[] = [];
  membershipProductTemplates.forEach((template: MembershipProductTemplate) => {
    const productCountry = template.countries.find(
      (c: MembershipProductCountry) => c.country === country
    );
    if (productCountry !== undefined) {
      membershipProducts.push({
        type: template.type,
        titleKey: template.titleKey,
        period: template.period,
        featureKeys: template.featureKeys,
        price: productCountry.price,
        currency: productCountry.currency,
      } as MembershipProduct);
    }
  });
  return membershipProducts;
};

/**
 * Returns the membership product with the canX properties set according to the user.
 * @param membershipProduct
 * @param userRole
 * @param activeMembershipType
 * @param pendingMembership
 */
const getProductAvailability = (
  membershipProduct: MembershipProduct,
  userRole: UserRole,
  activeMembershipType?: MembershipType,
  pendingMembership?: boolean
) => {
  // If user has a free premium membership, they cannot do anything
  if (activeMembershipType === MembershipType.PREMIUM_FREE) {
    return membershipProduct;
  }
  // Users should never see this type
  if (membershipProduct.type === MembershipType.PREMIUM_FREE) return null;

  if (membershipProduct.type === MembershipType.FREE) {
    return {
      ...membershipProduct,
      canCreate: userRole === UserRole.NONE,
    };
  }
  if (membershipProduct.type === MembershipType.PREMIUM_1_MONTH) {
    let canBuy = userRole === UserRole.NONE || userRole === UserRole.STANDARD;
    if (activeMembershipType !== membershipProduct.type && !pendingMembership) {
      canBuy = true;
    }
    const canSwitch =
      activeMembershipType !== undefined &&
      activeMembershipType !== membershipProduct.type &&
      !pendingMembership;
    return {
      ...membershipProduct,
      canBuy: canBuy,
      canSwitch: canSwitch,
    };
  }
  if (membershipProduct.type === MembershipType.PREMIUM_1_YEAR) {
    let canBuy = userRole === UserRole.NONE || userRole === UserRole.STANDARD;
    if (activeMembershipType !== membershipProduct.type && !pendingMembership) {
      canBuy = true;
    }
    const canSwitch =
      activeMembershipType !== undefined &&
      activeMembershipType !== membershipProduct.type &&
      !pendingMembership;
    return {
      ...membershipProduct,
      canBuy: canBuy,
      canSwitch: canSwitch,
    };
  }
  return membershipProduct;
};

/**
 * Get all membership products available to the current user with all canX properties set.
 * @param membershipProductTemplates
 * @param country
 */
const getAvailableMembershipProducts = (
  membershipProducts: MembershipProduct[],
  userRole: UserRole,
  activeMembershipType?: MembershipType,
  pendingMembership?: boolean
) => {
  const available: MembershipProduct[] = [];
  membershipProducts.forEach((product: MembershipProduct) => {
    const availableProduct = getProductAvailability(
      product,
      userRole,
      activeMembershipType,
      pendingMembership
    );
    if (availableProduct !== null) {
      available.push(availableProduct);
    }
  });
  return available;
};

const findCountryMembershipProduct = (
  membershipType: MembershipType,
  country: Country,
  membershipProductTemplates: MembershipProductTemplate[]
) => {
  const countryProducts = getMembershipProducts(
    membershipProductTemplates,
    country
  );
  return countryProducts.find((membershipProduct: MembershipProduct) => {
    return membershipProduct.type === membershipType;
  });
};

/**
 * Finds a membership that has start date later than today
 * @param membershipHistory
 * @returns
 */
const getPendingMembership = (membershipHistory: Membership[]) => {
  return membershipHistory.find((membership: Membership) => {
    const start = DateTimeTools.getDateFromDbFormat(membership.startDate);
    const today = new Date();
    return start.getTime() > today.getTime();
  });
};

const getCustomerInfoFromLocalStorage = () => {
  const customerInfo = localStorage.getItem("customerInfo");
  if (customerInfo !== null) {
    try {
      return JSON.parse(customerInfo) as Customer;
    } catch (e) {
      console.error("Failed to parse customer info from local storage");
    }
  }
  return null;
};

const setCustomerInfoInLocalStorage = (customerInfo: Customer) => {
  localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
};

/**
 * Store a deal token in local storage. The token is valid for 1 hour.
 * @param token
 */
const storeDealToken = (token: string) => {
  const item = {
    token: token,
    expiry: Date.now() + 1000 * 60 * 60, // 1 hours
  };
  localStorage.setItem("dealToken", JSON.stringify(item));
};

const getDealToken = () => {
  const item = localStorage.getItem("dealToken");
  if (item === null) return null;
  try {
    const token = JSON.parse(item);
    if (token.expiry < Date.now()) {
      localStorage.removeItem("dealToken");
      return null;
    }
    return token.token;
  } catch (e) {
    console.error("Failed to parse deal token from local storage");
    return null;
  }
};

export const MembershipsTools = {
  getMembershipProducts,
  getProductAvailability,
  getAvailableMembershipProducts,
  getPendingMembership,
  findCountryMembershipProduct,
  getCustomerInfoFromLocalStorage,
  setCustomerInfoInLocalStorage,
  storeDealToken,
  getDealToken,
};
