import { useEffect } from "react";
import { TextAndImageContent, TextAndImageLayout } from "../..";
import { Api } from "imagine-essentials";

type Props = {
  content: TextAndImageContent;
};

/**
 * Display a preview of the text and image content.
 */
export const TextAndImageContentView = (props: Props) => {
  useEffect(() => {}, []);

  const layoutClass =
    props.content.layout === TextAndImageLayout.IMAGE_LEFT
      ? "image-left"
      : "image-right";

  return (
    <div className={"content-area text-and-image " + layoutClass}>
      <div className={"image"}>
        <img
          className="fluid"
          src={Api.getHost() + props.content.filePath}
          alt={props.content.altText}
        />
      </div>
      <div className={"text"}>
        <div dangerouslySetInnerHTML={{ __html: props.content.html }}></div>
      </div>
    </div>
  );
};
