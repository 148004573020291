/**
 * Defines the types of membership, mainly for better data analysis. Use some of the
 * already existing or add new when neccessary. Never delete these.
 */
export enum MembershipType {
  FREE = 0,
  PREMIUM_1_MONTH = 1, // Premium membership 1 month
  PREMIUM_1_YEAR = 2, // Premium membership for 1 year
  PREMIUM_FREE = 3, // Use to give away freely to friends, family, beta testers etc.
  STRIPE = 4, // Handled by Stripe
}
