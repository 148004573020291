import { createSelector } from "@reduxjs/toolkit";
import {
  MembershipDeal,
  MembershipRootState,
  Subscription,
  SubscriptionInvoice,
} from "..";

/**
 * Some selectors are defined outside of the MembershipSelector object, in order to be reused within other selectors.
 */
const getActiveSubscription = createSelector(
  (state: MembershipRootState) => state.membership.subscriptions,
  (subscriptions) => {
    const active = subscriptions.find((sub) => sub.status === "active");
    if (active) {
      return active;
    }
    // If subscription has been extended, it will be in trialing state
    //(ghost subscriptions are also in trialing state, so always look for an active state subscription first)
    return subscriptions.find((sub) => sub.status === "trialing");
  }
);

export const MembershipSelector = {
  getStatus: (state: MembershipRootState) => {
    return state.membership.status;
  },
  getStripeStatus: (state: MembershipRootState) => {
    return state.membership.stripeStatus;
  },
  getSubscriptions: (state: MembershipRootState) => {
    return state.membership.subscriptions;
  },
  getPaymentMethod: (state: MembershipRootState) => {
    return state.membership.paymentMethod;
  },
  getCustomer: (state: MembershipRootState) => {
    return state.membership.customer;
  },
  getInvoices: createSelector(
    (state: MembershipRootState) => state.membership.subscriptions,
    (state: MembershipRootState) => state.membership.otherInvoices,
    (subscriptions, otherInvoices) => {
      const invoices: SubscriptionInvoice[] = [];
      subscriptions.forEach((sub) => {
        if (sub.invoices) {
          invoices.push(...sub.invoices.filter((inv) => inv.status === "paid"));
        }
      });

      return invoices;
    }
  ),
  getActiveSubscription: getActiveSubscription,
  getActiveInvoice: createSelector(
    getActiveSubscription,
    (activeSubscription) => {
      if (activeSubscription?.invoices) {
        return activeSubscription.invoices.find((invoice) => {
          if (invoice.status === "paid" || invoice.status === "open") {
            const startTimestamp = new Date(invoice.periodStart).getTime();
            const endTimestamp = new Date(invoice.periodEnd).getTime();
            const nowTimestamp = new Date().getTime(); // + 1000 * 60 * 60 * 24 * 365;
            return (
              startTimestamp <= nowTimestamp && nowTimestamp <= endTimestamp
            );
          }
          return false;
        });
      }
      return undefined;
    }
  ),
  getSelectedDisplayProduct: (state: MembershipRootState) => {
    return state.membership.selectedDisplayProduct;
  },
  getCheckoutStep: (state: MembershipRootState) => {
    return state.membership.checkoutStep;
  },
  getCustomerInfo: (state: MembershipRootState) => {
    return state.membership.customerInfo;
  },
  getPurchaseInvoice: (state: MembershipRootState) => {
    return state.membership.purchaseInvoice;
  },
  getClientSecret: (state: MembershipRootState) => {
    return state.membership.clientSecret;
  },
  getOrderStatus: (state: MembershipRootState) => {
    return state.membership.orderStatus;
  },
  getPaymentUpdated: (state: MembershipRootState) => {
    return state.membership.paymentUpdated;
  },
  // Old membership data
  getActiveMembership: (state: MembershipRootState) => {
    return state.membership.activeMembership;
  },
  getMembershipHistory: (state: MembershipRootState) => {
    return state.membership.membershipHistory;
  },
  getDeals: (state: MembershipRootState) => {
    return state.membership.deals;
  },
  getBonusDays: (state: MembershipRootState) => {
    return state.membership.bonusDays;
  },
  getActiveMembershipDeal: (state: MembershipRootState) => {
    if (state.membership.activeMembership === null) return null;
    if (
      state.membership.activeMembership.dealId &&
      state.membership.activeMembership.dealId > 0
    ) {
      const deal = state.membership.deals.find(
        (d: MembershipDeal) =>
          d.id === state.membership.activeMembership!.dealId!
      );
      return deal || null;
    }
    return null;
  },
  getContinuationMembershipDeal: (state: MembershipRootState) => {
    if (state.membership.activeMembership === null) return null;
    if (
      state.membership.activeMembership.dealId &&
      state.membership.activeMembership.dealId > 0
    ) {
      const deal = state.membership.deals.find(
        (d: MembershipDeal) =>
          d.id === state.membership.activeMembership!.dealId!
      );
      if (deal && deal.continuationDealId > 0) {
        const continuationDeal = state.membership.deals.find(
          (d: MembershipDeal) => d.id === deal.continuationDealId
        );
        return continuationDeal || null;
      }
    }
    return null;
  },
};
