import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User, UserThunks } from "..";
// import i18n from "../../../../i18n";

import { Preferences } from "../types/Preferences";
import { DataResponse, RequestStatus } from "imagine-essentials";

interface UserState {
  user: User | null;
  preferences: Preferences;
  status: RequestStatus;
  usedFeatures: number[];
  apiVersion: string;
}

// All data related to the user
const initialState: UserState = {
  user: null,
  preferences: {},
  status: RequestStatus.IDLE,
  usedFeatures: [],
  apiVersion: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setPreferences: (state, action: PayloadAction<Preferences>) => {
      state.preferences = action.payload;
    },
    track: (state, action: PayloadAction<number>) => {
      // Only track actions for logged in users
      if (state.user !== null && !state.usedFeatures.includes(action.payload)) {
        state.usedFeatures.push(action.payload);
      }
    },
    clearUser: (state) => {
      state.user = null;
      state.status = RequestStatus.IDLE;
    },
    clearPreferences: (state) => {
      state.preferences = {};
    },
    setApiVersion: (state, action: PayloadAction<string>) => {
      state.apiVersion = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(UserThunks.fetchLoggedInUser.pending, (state) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(
        UserThunks.fetchLoggedInUser.fulfilled,
        (
          state,
          action: PayloadAction<
            DataResponse<{ user: User; preferences: Preferences }>
          >
        ) => {
          if (action.payload.apiVersion) {
            state.apiVersion = action.payload.apiVersion;
          }
          if (action.payload.success && action.payload.data) {
            // When in strict mode, this is called twice. Make sure second call does no override default data
            if (
              action.payload.data.preferences &&
              Object.keys(action.payload.data.preferences).length > 0
            ) {
              state.preferences = action.payload.data.preferences;
            }
            state.user = action.payload.data.user;
          }
          state.status = RequestStatus.READY;
          // Set language
          // if (state.user?.language !== undefined) {
          //   i18n.changeLanguage(state.user.language);
          // }
        }
      )
      .addCase(UserThunks.fetchLoggedInUser.rejected, (state) => {
        state.status = RequestStatus.FAILED;
      });
  },
});

export const UserActions = userSlice.actions;

export const userReducer = userSlice.reducer;
