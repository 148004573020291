export enum ThemeColor {
  NONE = 0,
  ACCENT = 1,
  HIGHLIGHT = 2,
  SECONDARY = 3,
  MAIN = 4,
  WARNING = 5,
  DANGER = 6,
  LIGHT = 7,
  DARK = 8,
  _SIZE = 9,
}
