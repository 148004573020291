import { Language } from "imagine-i18n";
import { KeyPage, LanguageUrlMap, Page } from "..";
import ImagineConfig from "../../../../../ImagineConfig";
import { Api } from "imagine-essentials";

const findKeyPage = (keyPages: KeyPage[], id: number) => {
  return keyPages.find((keyPage) => keyPage.id === id);
};

/**
 * Get the title of the page linked to a key page for a given language. If the page does not exist in
 * the given language, the title in the fallback language is returned. If the page does not exist in the
 * fallback language, the title in the first language is returned.
 * @param keyPage
 * @param language
 * @returns
 */
const getPageTitle = (id: number, keyPages: KeyPage[], language: Language) => {
  const keyPage = findKeyPage(keyPages, id);
  if (!keyPage) return "";
  if (keyPage.pages.length === 0) return "";
  if (keyPage.pages.length === 1) return keyPage.pages[0].title;
  const page = keyPage.pages.find((page) => page.language === language);
  if (page) return page.title;
  if (ImagineConfig.defaultLanguage === undefined)
    throw new Error("Default language is not defined in ImagineConfig");
  const fallbackLanguage = ImagineConfig.defaultLanguage as Language;
  const altPage = keyPage.pages.find(
    (page) => page.language === fallbackLanguage
  );
  return altPage?.title || keyPage.pages[0].title;
};

const getFullPageUrl = (page: Page) => {
  if (ImagineConfig.languageUrlMap === undefined)
    throw new Error("Language url map is not defined in ImagineConfig");
  const languageUrlMap = ImagineConfig.languageUrlMap as LanguageUrlMap[];
  const languageUrl = languageUrlMap.find(
    (languageUrl) => languageUrl.language === page.language
  );

  if (!languageUrl) {
    console.log("No language URL map found for language: " + page.language);
    return "";
  }
  return languageUrl.urlPrefix + page.url;
};

/**
 * Get the URL of the page linked to a key page for a given language. If the page does not exist in
 * the given language, the URL in the fallback language is returned. If the page does not exist in the
 * fallback language, the URL in the first language is returned.
 * @param keyPage
 * @param language
 * @param fallbackLanguage
 * @param languageUrlMap - a list of language URL mappings
 * @returns
 */
const getPageUrl = (id: number, keyPages: KeyPage[], language: Language) => {
  const keyPage = findKeyPage(keyPages, id);
  if (!keyPage) return "";
  if (keyPage.pages.length === 0) return "";
  // If there is only one page, return its URL
  if (keyPage.pages.length === 1) return getFullPageUrl(keyPage.pages[0]);
  // Find the page in the requested language
  const page = keyPage.pages.find((page) => page.language === language);
  if (page) return getFullPageUrl(page);
  // Find the page in the fallback language
  if (ImagineConfig.defaultLanguage === undefined)
    throw new Error("Default language is not defined in ImagineConfig");
  const fallbackLanguage = ImagineConfig.defaultLanguage as Language;
  const altPage = keyPage.pages.find(
    (page) => page.language === fallbackLanguage
  );
  if (altPage) return getFullPageUrl(altPage);
  // Return the URL of the first page (last resort)
  return getFullPageUrl(keyPage.pages[0]);
};

const getAbsolutePageUrl = (
  id: number,
  keyPages: KeyPage[],
  language: Language
) => {
  let relativeUrl = getPageUrl(id, keyPages, language);
  if (relativeUrl[0] !== "/") {
    relativeUrl = "/" + relativeUrl;
  }

  return Api.getHost() + relativeUrl;
};

export const KeyPageUtils = {
  findKeyPage,
  getPageTitle,
  getFullPageUrl,
  getPageUrl,
  getAbsolutePageUrl,
};
