export enum DateRange {
  NONE = 0,
  CUSTOM,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_3_MONTHS,
  LAST_6_MONTHS,
  LAST_YEAR,
  _SIZE,
}
