import { useEffect, useMemo, useState } from "react";
import { Icon } from "..";

interface Props {
  value: string;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  testId?: string;
  onChange?: (value: string) => void;
  onFinish?: (value: string) => void; // Enter, tab or lost focus
  onEnterPress?: (value: string) => void;
  elementId?: string;
  className?: string;
  password?: boolean;
  invalid?: boolean;
  multiline?: boolean;
  prependText?: string;
  prependIcon?: string;
  height?: "sm" | "md" | "lg" | "xs"; // Height of the textarea, only used when multiline is true
  resizable?: boolean; // If the textarea should be resizable
  onClick?: () => void; // When clicked on the prepend element
}

export const TextInput = (props: Props) => {
  const [internalValue, setInternalValue] = useState(props.value || "");

  const groupClass = useMemo(() => {
    let className = "";
    if (props.size !== undefined && props.size !== "md")
      className += " input-" + props.size;
    if (props.disabled) className += " disabled";
    if (props.invalid) className += " invalid";
    if (props.className) className += " " + props.className;

    return className;
  }, [props.className, props.disabled, props.invalid, props.size]);

  const type = props.password ? "password" : "text";

  const inputElementClass = useMemo(() => {
    let className = "";

    if (props.multiline)
      className += " height-" + (props.height ? props.height : "sm");
    if (!props.resizable) className += " no-resize";

    return className;
  }, [props.height, props.multiline, props.resizable]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateValue = (event: any) => {
    setInternalValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const finishEditing = () => {
    if (props.onFinish) {
      props.onFinish(internalValue);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkKeypress = (event: any) => {
    if (props.multiline) return;
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.target.blur();
      if (props.onFinish) {
        props.onFinish(internalValue);
        setInternalValue(props.value);
      }
      if (props.onEnterPress) {
        props.onEnterPress(internalValue);
        setInternalValue(props.value);
      }
    }
  };

  useEffect(() => {
    if (props.value !== undefined) {
      setInternalValue(props.value);
    }
  }, [props.value]);

  return (
    <div className={"input-group" + groupClass}>
      {props.multiline && (
        <div className="input-wrapper">
          <textarea
            className={"input" + inputElementClass}
            onChange={updateValue}
            onKeyDown={checkKeypress}
            onBlur={finishEditing}
            readOnly={props.disabled}
            id={props.elementId}
            value={internalValue}
          ></textarea>
        </div>
      )}
      {!props.multiline && (
        <div className="input-wrapper">
          <input
            type={type}
            className="input"
            onChange={updateValue}
            onKeyDown={checkKeypress}
            onBlur={finishEditing}
            readOnly={props.disabled}
            id={props.elementId}
            value={internalValue}
          />
        </div>
      )}
      {(props.prependIcon || props.prependText) && (
        <div className="prepend-wrapper">
          <span
            className={
              "input-group-prepend" + (props.onClick ? " clickable" : "")
            }
            onClick={props.onClick}
          >
            {props.prependIcon && (
              <Icon name={props.prependIcon} className="input-icon" />
            )}
            {props.prependText && props.prependText}
          </span>
        </div>
      )}
    </div>
  );
};

export default TextInput;
