/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from "imagine-ui";
import {
  AccordionContent,
  AccordionContentView,
  CardsContent,
  CardsContentView,
  ContentStyle,
  ContentTypes,
  ImageContent,
  ImageContentView,
  NavigationContent,
  NavigationContentView,
  PageContent,
  PageTheme,
  SnippetBaseProps,
  SnippetContent,
  SnippetContentViewProps,
  TextAndImageContent,
  TextAndImageContentView,
  TextContent,
  TextContentView,
  VideoContent,
  VideoContentView,
} from "../..";
import React from "react";

interface Props {
  content: PageContent;
  editMode?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  SnippetContentViewComponent: React.ComponentType<SnippetContentViewProps>;
  snippetProps: SnippetBaseProps;
  // snippetContentView: React.ReactNode;
}

export const ContentView = (props: Props) => {
  const getContentBlockWrapperClass = (content: PageContent) => {
    let className = "content-wrapper";

    if ((content as any).contentStyle !== undefined) {
      if ((content as any).contentStyle === ContentStyle.BOXED)
        className += " boxed";
      if ((content as any).contentStyle === ContentStyle.FLOATING) {
        className += " floating";
        if ((content as any).backgroundColor !== undefined)
          className +=
            " " +
            PageTheme.getThemeColorBackgroundClass(
              (content as any).backgroundColor
            );

        if ((content as any).textColor !== undefined) {
          className +=
            " " + PageTheme.getThemeColorTextClass((content as any).textColor);
        }
      }
    }

    return className;
  };

  const getContentBlockClass = (content: PageContent) => {
    let className = "content-block";

    if ((content as any).contentStyle === ContentStyle.BOXED) {
      if ((content as any).backgroundColor !== undefined)
        className +=
          " " +
          PageTheme.getThemeColorBackgroundClass(
            (content as any).backgroundColor
          );

      if ((content as any).textColor !== undefined) {
        className +=
          " " + PageTheme.getThemeColorTextClass((content as any).textColor);
      }
    }

    return className;
  };

  const requestDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const requestEdit = () => {
    if (props.onEdit) {
      props.onEdit();
    }
  };

  // const contentStyle = useMemo(() => {
  //   const style = { ...props.content } as any;
  //   if (style.html !== undefined) delete style.html;
  //   if (style.raw !== undefined) delete style.raw;
  //   if (style.items !== undefined) delete style.items;
  //   return style;
  // }, [props.content]);

  return (
    <>
      {props.editMode && (
        <div className="row items-right">
          <Button
            color="warning"
            onClick={requestEdit}
            transparent
            icon="edit"
          />
          <Button
            color="danger"
            transparent
            onClick={requestDelete}
            icon="trash"
          />
        </div>
      )}
      <div className={getContentBlockWrapperClass(props.content)}>
        <div className={getContentBlockClass(props.content)}>
          {/* <p>{JSON.stringify(contentStyle)}</p> */}

          {props.content.type === ContentTypes.TEXT && (
            <TextContentView content={props.content as TextContent} />
            // <div dangerouslySetInnerHTML={{__html: (content as TextContent).text}}></div>
          )}
          {props.content.type === ContentTypes.IMAGE && (
            <ImageContentView content={props.content as ImageContent} />
          )}
          {props.content.type === ContentTypes.VIDEO && (
            <VideoContentView content={props.content as VideoContent} />
          )}
          {props.content.type === ContentTypes.TEXT_AND_IMAGE && (
            <TextAndImageContentView
              content={props.content as TextAndImageContent}
            />
          )}
          {
            props.content.type === ContentTypes.SNIPPET && (
              <props.SnippetContentViewComponent
                content={props.content as SnippetContent}
                props={props.snippetProps}
              />
            )
            // React.createElement(props.snippetContentView, { content: props.content as SnippetContent })

            // <SnippetContentView content={props.content as SnippetContent} />
          }
          {props.content.type === ContentTypes.CARDS && (
            <CardsContentView content={props.content as CardsContent} />
          )}
          {props.content.type === ContentTypes.ACCORDION && (
            <AccordionContentView content={props.content as AccordionContent} />
          )}
          {props.content.type === ContentTypes.NAVIGATION && (
            <NavigationContentView
              content={props.content as NavigationContent}
            />
          )}
        </div>
      </div>
    </>
  );
};
